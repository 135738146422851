/* tslint:disable */
/* eslint-disable */
/**
 * Registration API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { mapValues } from '../runtime';
import type { SsoSigninMethodRestriction } from './';
import {
    SsoSigninMethodRestrictionFromJSON,
    SsoSigninMethodRestrictionFromJSONTyped,
    SsoSigninMethodRestrictionToJSON,
} from './';
import type { UnclaimedComatchAccountRestriction } from './';
import {
    UnclaimedComatchAccountRestrictionFromJSON,
    UnclaimedComatchAccountRestrictionFromJSONTyped,
    UnclaimedComatchAccountRestrictionToJSON,
} from './';

/**
 * 
 * @export
 * @interface SigninMethodRestrictions
 */
export interface SigninMethodRestrictions {
    /**
     * Whether the user is allowed to sign in using the provided email together with a password.
     * @type {boolean}
     * @memberof SigninMethodRestrictions
     */
    emailAndPasswordAuthorized: boolean;
    /**
     * 
     * @type {SsoSigninMethodRestriction}
     * @memberof SigninMethodRestrictions
     */
    ssoRestriction?: SsoSigninMethodRestriction;
    /**
     * 
     * @type {UnclaimedComatchAccountRestriction}
     * @memberof SigninMethodRestrictions
     */
    unclaimedComatchAccountRestriction?: UnclaimedComatchAccountRestriction;
}

/**
 * Check if a given object implements the SigninMethodRestrictions interface.
 */
export function instanceOfSigninMethodRestrictions(value: object): value is SigninMethodRestrictions {
    if (!('emailAndPasswordAuthorized' in value) || value['emailAndPasswordAuthorized'] === undefined) return false;
    return true;
}

export function SigninMethodRestrictionsFromJSON(json: any): SigninMethodRestrictions {
    return SigninMethodRestrictionsFromJSONTyped(json, false);
}

export function SigninMethodRestrictionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): SigninMethodRestrictions {
    if (json == null) {
        return json;
    }
    return {
        
        'emailAndPasswordAuthorized': json['emailAndPasswordAuthorized'],
        'ssoRestriction': json['ssoRestriction'] == null ? undefined : SsoSigninMethodRestrictionFromJSON(json['ssoRestriction']),
        'unclaimedComatchAccountRestriction': json['unclaimedComatchAccountRestriction'] == null ? undefined : UnclaimedComatchAccountRestrictionFromJSON(json['unclaimedComatchAccountRestriction']),
    };
}

export function SigninMethodRestrictionsToJSON(value?: SigninMethodRestrictions | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'emailAndPasswordAuthorized': value['emailAndPasswordAuthorized'],
        'ssoRestriction': SsoSigninMethodRestrictionToJSON(value['ssoRestriction']),
        'unclaimedComatchAccountRestriction': UnclaimedComatchAccountRestrictionToJSON(value['unclaimedComatchAccountRestriction']),
    };
}

