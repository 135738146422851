import {useRuntimeConfig} from '#imports';
import {defineNuxtRouteMiddleware, navigateTo, useNuxtApp} from '#app';
import {storeToRefs} from 'pinia';
import {useAuth} from '../store/auth.store';
import type {RouteLocationNormalized} from 'vue-router';

export default defineNuxtRouteMiddleware((to) => {
    const nuxtApp = useNuxtApp();
    const {$pinia} = nuxtApp;

    const authStore = useAuth($pinia);
    const {isUmbrellaCompany} = storeToRefs(authStore);
    const runtimeConfig = useRuntimeConfig();

    function isTryingToAccessOutsideCompliance(to: RouteLocationNormalized): boolean {
        const baseURL = runtimeConfig.app.baseURL;
        const currentPath = baseURL === '/' ? to.path : baseURL + to.path;

        const allowList = [
            '/*/signout',
            '/emailvalidation/*',
            '/freelancer/company',
            '/freelancer/company/*',
            '/freelancer',
            '/project-payment/freelancer',
            '/*/signin',
        ];

        const forbiddenList = ['dashboard/freelancer'];
        return (
            !allowList.some((allowedPath: string) => new RegExp(allowedPath).test(currentPath)) ||
            forbiddenList.some((forbiddenPath) => new RegExp(forbiddenPath).test(currentPath))
        );
    }

    if (authStore.loggedIn && isUmbrellaCompany.value && isTryingToAccessOutsideCompliance(to)) {
        return navigateTo('/freelancer/company', {external: true});
    }
});
