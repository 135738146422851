import type {Ref} from 'vue';
import type {PostProcessorModule} from 'i18next';

const NAME = 'malt-nuxt-i18n-audit';

function MaltI18nAuditPostProcessor(application = 'undefined-application-config', storedKeys: Ref<Set<string>>): PostProcessorModule {
    return {
        type: 'postProcessor',
        name: NAME,
        process(value: string, key: string | string[]): string {
            if (typeof key === 'string') {
                storedKeys.value.add(application + ':' + key);
            } else {
                for (const k of key) {
                    storedKeys.value.add(application + ':' + k);
                }
            }
            return value;
        },
    };
}

export default MaltI18nAuditPostProcessor;
