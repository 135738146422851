import {defineNuxtRouteMiddleware, useAuth, useLogger, useNuxtApp} from '#imports';
import {safelyNavigateTo} from '#malt/nuxt-utils-module';
import {useSigninStore} from '~/store/signin.store';

// Unfortunately, I failed to write a working tests for this middleware:
// - it's impractical and almost meaningless using unit tests and painfully mocking Nuxt internals
// - I could only get infinite redirections when using Playwright
export default defineNuxtRouteMiddleware(async (to) => {
    const logger = useLogger();
    const nuxtApp = useNuxtApp();
    const {$pinia} = nuxtApp;
    const authStore = useAuth($pinia);
    const {urlUsesAValidDifferentHost} = useSigninStore($pinia);

    if (!(await authStore.isLogged())) {
        return;
    }

    // this page can be accessed while being logged in
    if (to.path.startsWith('/password-renewal/')) {
        return;
    }

    // dev only
    if (['/registration/dev-home', '/registration/signin'].includes(to.path)) {
        return;
    }

    const redirectPath = <string | null>to.query?.redirect;
    const selectedIdentityType = authStore.getSelectedIdentity?.identityType;

    async function navigateToReceivedRedirectPathOrDefault(defaultPath: string) {
        if (!redirectPath) {
            return safelyNavigateTo(defaultPath, {externalToThisApp: true});
        }

        // /!\ The part below has been copied/pasted from pages/login/index.vue.
        // No need to make it cleaner: we're killing Insights
        // ----
        // We're fine with redirecting the user to another one of our hosts only, but not to
        // any other hosts (to prevent Open Redirect attacks).
        // That being said, it doesn't make any sense to do so, since the user wouldn't be logged
        // in on the host they are redirected to.
        // But, in the case of Insights, we need to redirect the user to Insights, which will
        // then ask the source host for an authentication using OAuth.
        const insightsLogin = to.path.endsWith('/insights');
        if (insightsLogin && (await urlUsesAValidDifferentHost(redirectPath))) {
            return safelyNavigateTo(redirectPath, {externalToThisHost: true});
        } else {
            return safelyNavigateTo(redirectPath, {externalToThisApp: true});
        }
    }

    switch (selectedIdentityType) {
        case 'CLIENT': {
            return navigateToReceivedRedirectPathOrDefault('/project-client/find-freelancers');
        }
        case 'FREELANCE': {
            return navigateToReceivedRedirectPathOrDefault('/dashboard/freelancer');
        }
        case 'UNDEFINED': {
            return navigateToReceivedRedirectPathOrDefault('/account/membership');
        }
        default: {
            logger.error(`Unexpected identity type: ${selectedIdentityType}`);
        }
    }
});
