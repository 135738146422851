import {useAsyncData} from '#app';
import {defineNuxtPlugin, useLogger, useRuntimeConfig, useTranslationAdminTools, useUniversalFetch} from '#imports';
import type {InitOptions} from 'i18next';
import {createInstance} from 'i18next';
import ChainedBackend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import resourcesToBackend from 'i18next-resources-to-backend';
import ICU from 'i18next-icu';
import I18NextVue from 'i18next-vue';
import type {App} from 'vue';
import {useLocale} from '../composables/useLocale';
import {useStoredKeys} from '../composables/useTranslationAdminTools';
import MaltNuxtI18nDebugPostProcessor from '../post-processors/MaltI18nDebugPostProcessor';
import MaltNuxtI18nLogPostProcessor from '../post-processors/MaltI18nLogPostProcessor';
import MaltI18nAuditPostProcessor from '../post-processors/MaltI18nAuditPostProcessor';
import {localeCacheKeys} from '#build/localeCacheKeys';
import {name} from '../../common';
import {en} from '../fallback-locale';

export default defineNuxtPlugin(async (nuxtApp) => {
    const runtime = useRuntimeConfig();
    const application = runtime.public.application;
    const storedKeys = useStoredKeys();
    const logger = useLogger();
    const {locale, refreshLocale, languageCode} = useLocale();
    await useAsyncData('locale', () => refreshLocale());

    const {isActivatedDebugTool, isActivatedLogsTool} = useTranslationAdminTools();

    try {
        // This is very important to create a new instance of i18next for each Nuxt app
        // (to avoid sharing the same instance for SSR applications)

        // NB: We could try to keep a single instance on the server side but we would have to keep it somewhere and clone it everytime.
        // It could improve the memory used by the i18next module but is a bit hard to do for a small impact
        const i18next = createInstance();

        const {target} = runtime.public[name] as {target: string};
        let targetDir = target.split('/public')[1];
        targetDir = target.endsWith('/') ? targetDir : `${targetDir}/`;
        const loadPath = `${targetDir}locales.{{lng}}.json`;

        const i18nConfiguration: InitOptions = {
            lng: languageCode.value,
            load: 'languageOnly',
            debug: false,
            fallbackLng: 'en',
            supportedLngs: ['fr', 'en', 'es', 'de', 'nl'],
            backend: {
                backends: [
                    HttpBackend, // This plugin allows to lazy load the translation files we need
                    resourcesToBackend({
                        // This plugin allows to have a fallback in english. If the lazy loaded files can not be loaded, we use this translation that is directly included in the bundle
                        en: {
                            translation: en,
                        },
                    }),
                ],
                backendOptions: [
                    {
                        loadPath,
                        request: async (_: any, url: string, __: any, callback: any) => {
                            try {
                                // We do not want to load the english file because it's already included in the bundle
                                if (url.includes('locales.en.json')) {
                                    callback(new Error('English file is already included in the bundle'));
                                    return;
                                }

                                const cacheKey = localeCacheKeys[languageCode.value];

                                const baseUrlToUse = runtime.app.cdnURL || runtime.app.baseURL;
                                const response = await useUniversalFetch(`${url}?${cacheKey}`, {baseURL: baseUrlToUse});
                                if (response) {
                                    callback(null, {status: 200, data: response});
                                } else {
                                    callback(new Error('Error while fetching current translation file'));
                                }
                            } catch (e) {
                                callback(e);
                            }
                        },
                        reloadInterval: false,
                    },
                ],
            },
            postProcess: [],
        };

        i18next.use(MaltI18nAuditPostProcessor(application, storedKeys));
        (i18nConfiguration.postProcess as string[]).push('malt-nuxt-i18n-audit');

        if (isActivatedDebugTool()) {
            i18next.use(MaltNuxtI18nDebugPostProcessor);
            (i18nConfiguration.postProcess as string[]).push('malt-nuxt-i18n-debug');
        }
        if (isActivatedLogsTool()) {
            i18next.use(MaltNuxtI18nLogPostProcessor);
            (i18nConfiguration.postProcess as string[]).push('malt-nuxt-i18n-log');
        }

        i18next.use(ICU);
        i18next.use(ChainedBackend);

        await i18next
            // Init i18next. For all options read: https://www.i18next.com/overview/configuration-options
            .init(i18nConfiguration);

        (nuxtApp.vueApp as App).use(I18NextVue, {i18next});

        return {
            provide: {
                i18next,
            },
        };
    } catch (err: unknown) {
        logger.error(`Something went wrong loading i18next (determined locale: ${locale.value})`, err);
        throw new Error(`Something went wrong loading i18next (determined locale: ${locale.value})`);
    }
});
