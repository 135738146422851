import {defineNuxtPlugin, useStoredKeys, useUniversalPost} from '#imports';

function isLocalStorageSupported() {
    if (typeof localStorage !== 'undefined') {
        try {
            const x = '__storage_test__';
            localStorage.setItem(x, x);
            localStorage.removeItem(x);
            return true;
        } catch {
            return false;
        }
    }
    return false;
}

export default defineNuxtPlugin({
    parallel: true,
    setup(nuxtApp) {
        const newStoredKeys = useStoredKeys();

        nuxtApp.hook('app:mounted', () => {
            if (!isLocalStorageSupported()) {
                return;
            }

            // restart from zero to be sure keys are resent on a daily basis
            localStorage.removeItem('keysAlreadyPushedStr');

            setInterval(() => {
                if (newStoredKeys.value.size > 0) {
                    const storedKeysStr = localStorage.getItem('storedKeys');
                    const storedKeys: Set<string> = new Set(storedKeysStr ? JSON.parse(storedKeysStr) : []);

                    newStoredKeys.value.forEach((key) => storedKeys.add(key));
                    try {
                        localStorage.setItem('storedKeys', JSON.stringify(Array.from(storedKeys)));
                    } catch (e: any) {
                        // The stored keys should be purged on successful push to the audit api
                        // if they are not for some reason, the storage will accumulate and end up
                        // exceeding the max allowed storage size.
                        // The following is an attempt to detect and recover from this.
                        if (e instanceof DOMException && e.name === 'QuotaExceededError' && !storedKeysStr) {
                            localStorage.setItem('storedKeys', JSON.stringify(Array.from(storedKeys)));
                        }
                    }
                    newStoredKeys.value.clear();
                }
            }, 3000);

            setInterval(async () => {
                const storedKeysStr = localStorage.getItem('storedKeys');
                const storedKeys: string[] = storedKeysStr ? JSON.parse(storedKeysStr) : [];

                const keysAlreadyPushedStr = localStorage.getItem('keysAlreadyPushed');
                const keysAlreadyPushed: Set<string> = new Set(keysAlreadyPushedStr ? JSON.parse(keysAlreadyPushedStr) : []);

                const keysToPush = storedKeys.filter((key) => !keysAlreadyPushed.has(key));

                if (keysToPush.length > 0) {
                    try {
                        await useUniversalPost('/api/i18n/audit', {body: keysToPush});
                    } catch {
                        // Do nothing
                    }
                }
                // Whatever the result let's consider that the keys have been pushed, to avoid some infinite loops
                localStorage.setItem('keysAlreadyPushed', JSON.stringify([...keysAlreadyPushed, ...keysToPush]));
                localStorage.removeItem('storedKeys');
            }, 60000);
        });
    },
});
