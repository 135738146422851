import {defineNuxtRouteMiddleware, useCookie, useNuxtApp, useRuntimeConfig} from '#imports';
import {useAuth} from '../store/auth.store';
import {safelyNavigateTo} from '#malt/nuxt-utils-module';

/**
 * For every attempt of the user to navigate somewhere, we check whether a workflow is in progress
 * that would require us to force back to user to it. Typically we do this for client signups.
 *
 * BUT, we only do so if a cookie previously tells us that it makes sense to do that check.
 * Indeed, it means we will call the backend and we would prefer to avoid it unless absolutely necessary.
 *
 * The value of the cookie is set by the application in charge of the workflow when appropriate (see
 * for example: signup-client/signup-client-front/src/store/signup.store.ts).
 * When a workflow is complete or cancelled, the server will tell us and we can remove the cookie.
 *
 * Note: in case of a client signup, because a signup may continue after the user account has been
 * created, several days later, and with another browser, the "auth" middleware will also check
 * whether a signup is in progress when accessing pages which require the user to be logged in.
 * So this global middleware is not the whole story.
 */
export default defineNuxtRouteMiddleware(async (to) => {
    const nuxtApp = useNuxtApp();
    const {$pinia} = nuxtApp;

    const store = useAuth($pinia);

    const pathOfWorkflowInProgressCookie = useCookie<string | null>('path-of-mandatory-workflow-likely-in-progress');
    const pathOfWorkflowLikelyInProgress = pathOfWorkflowInProgressCookie.value?.split('?')[0];

    if (!pathOfWorkflowLikelyInProgress) {
        return;
    }

    const baseURL = useRuntimeConfig().app.baseURL;
    const currentPath = baseURL === '/' ? to.path : baseURL + to.path;

    if (
        // we must let the user consult these pages at all times
        currentPath === '/legal' ||
        currentPath.startsWith('/about/privacy')
        // add more here if needed
    ) {
        return;
    }

    if (!shouldRedirectUserTo(pathOfWorkflowLikelyInProgress)) {
        // we're already in the right application, so we're likely already on the intended page,
        // and otherwise that application can decide by itself what to do
        return;
    }

    function shouldRedirectUserTo(pathWhereToRedirectUser: string) {
        // if you are client and have a workflow in progress and
        // you have not already verified you email you should go to /signup-client/email-verification
        // but you may need to change you email in order to verify it so you are allowed to go to /account/email
        if (currentPath.startsWith('/account/email') && pathWhereToRedirectUser.startsWith('/signup-client/email-verification')) {
            return false;
        }
        return currentPath !== pathWhereToRedirectUser && (pathWhereToRedirectUser === '/' || !currentPath.startsWith(pathWhereToRedirectUser));
    }

    const {pathWhereToRedirectUser} = await store.checkIfLoggedOrIfNeedingRedirect();

    if (pathWhereToRedirectUser && shouldRedirectUserTo(pathWhereToRedirectUser)) {
        return safelyNavigateTo({path: pathWhereToRedirectUser}, {externalToThisApp: true});
    }
});
