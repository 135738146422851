import {watch} from 'vue';
import {useCookie, useState} from 'nuxt/app';

export function useMaltVisitorId() {
    const maltVisitorIdCookie = useCookie('malt-visitorId');
    // Default state comes from cookie
    const maltVisitorId = useState('malt-visitorId', () => maltVisitorIdCookie.value);

    watch(maltVisitorId, (value) => {
        // Update cookie when state changes
        maltVisitorIdCookie.value = value;
    });

    return maltVisitorId;
}
