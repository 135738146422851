import {type App, type Plugin} from 'vue';

/**
 * Specs :
 * - We display a notification to help devs understand why the UI is full of red dashed components
 * - The notification does not have a timeout
 */
const DeprecatedWebcomponentsPlugin: Plugin = {
    // Disable option allows to remove the feature for specific Nuxt applications, as the plugin is injected within nuxt-joy-module
    install: (app: App, options: {disable: boolean} = {disable: false}) => {
        const bodyClass = 'deprecated-wc';
        let atLeastOneComponentIsMounted = false;

        app.mixin({
            mounted() {
                /**
                 * mounted hook is called for each component instance in the application.
                 * We don't want to run the code hundreds of times so we set the atLeastOneComponentIsMounted to true after the first call.
                 */
                if (atLeastOneComponentIsMounted === true) {
                    return;
                }

                if (process.env.NODE_ENV === 'development' && options.disable === false) {
                    atLeastOneComponentIsMounted = true;

                    document.body.classList.add(bodyClass);

                    // const {snackbarEventBus} = pushVJoySnackbar({
                    //     disableStacking: true,
                    //     props: {
                    //         header: '[DEVS ONLY]',
                    //         message: 'Red dashed webcomponents = components that we need to migrate to Vue (VJoy tags)',
                    //         actionText: 'Read more',

                    //         level: 'error',
                    //         duration: 'forever',
                    //     },
                    // });

                    // snackbarEventBus.on('snackbar:action-click', () => {
                    //     window.open('https://doc.malt.tech/joy-storybook/?path=/docs/components-availability--docs');
                    // });

                    // For the moment we don't store the "closing" action in the localStorage
                    // snackbarEventBus.on('snackbar:hide', () => {
                    //     localStorage.setItem(localStorageKey, `${atLeastOneComponentIsMounted}`);
                    // });
                }
            },
        });
    },
};

export {DeprecatedWebcomponentsPlugin};
