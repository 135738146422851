import {watch} from 'vue';
import {defineNuxtPlugin} from '#imports';
import {useDataLayer} from '../composables/useDataLayer';

function frenchFormattedDateToISOString(frenchDate: string): string | null {
    if (!frenchDate) {
        return null;
    }

    const [day, month, year] = frenchDate.split('/');

    const timezoneOffset = new Date().getTimezoneOffset() * 60000;

    try {
        const isoStringDate = new Date(new Date(parseInt(year), parseInt(month) - 1, parseInt(day)).getTime() - timezoneOffset).toISOString();

        return isoStringDate;
    } catch {
        return null;
    }
}

export default defineNuxtPlugin(() => {
    const dataLayer = useDataLayer();

    window.addEventListener('hotjar_loaded', () => {
        watch(
            dataLayer,
            () => {
                if (dataLayer.value.name && window.hj) {
                    const {admin, identityType, tiering, clientMaturity, serviceLevel, lastMissionDate, lastQuoteDate, companyCreationDate}: any = {
                        ...dataLayer.value,
                    };

                    window.hj('identify', dataLayer.value.user_id, {
                        admin: admin === 'true',
                        identityType,
                        tiering,
                        clientMaturity,
                        serviceLevel,
                        lastMissionDate: frenchFormattedDateToISOString(lastMissionDate),
                        lastQuoteDate: frenchFormattedDateToISOString(lastQuoteDate),
                        companyCreationDate: frenchFormattedDateToISOString(companyCreationDate),
                    });
                }
            },
            {immediate: true},
        );
    });
});
