import {defineNuxtRouteMiddleware, navigateTo, useNuxtApp} from '#app';
import {useAuth} from '../store/auth.store';
import type {RouteLocationNormalized} from 'vue-router';

export default defineNuxtRouteMiddleware((to) => {
    const nuxtApp = useNuxtApp();
    const {$pinia} = nuxtApp;

    const authStore = useAuth($pinia);

    const allowList = ['/*/signout', '/profile/freelancer-signup/*', '/freelancer-signup/*', '/account/*', '/*/signin'];

    function isTryingToAccessOutsideProfileSignupCompletion(to: RouteLocationNormalized): boolean {
        // @ts-ignore
        return !allowList.some((allowedPath: string) => new RegExp(allowedPath).test(to.href));
    }
    if (authStore.loggedIn && authStore.isFreelancerApplicationInProgress() && isTryingToAccessOutsideProfileSignupCompletion(to)) {
        return navigateTo('/profile/freelancer-signup/waiting-room/strategy', {external: true});
    }
});
