import {pushVJoySnackbar} from '@maltjoy/core-vue';
import {defineStore} from 'pinia';
import {ref, watch} from 'vue';
import {useHosts, useLogger, useTranslation} from '#imports';
import type {Host} from '@malt/nuxt-host-module';
import {readonlyState} from '#malt/nuxt-utils-module';
import type {CurrentSessionDetails, FetchError, SigninMethodRestrictions} from '@api';
import {SigninApi} from '@api';
import {useRedirectAfterSignin} from '~/composables/useRedirectAfterSignin';
import {apiConfiguration} from '~/configuration/apiConfiguration';

interface ResponseWrapper<T> {
    data: T | null;
    error: FetchError | null;
}

type SigninMethod = 'EMAIL_AND_PASSWORD' | 'UNCLAIMED_COMATCH_ACCOUNT' | 'SSO';

export function urlUsesOneOfDomains(url: string, countries: Host[]) {
    const ourDomains = countries.flatMap((country) => country.languages).map((lang) => lang.host);

    const domainsToCheck = ourDomains
        .map((domain) => {
            // keep the 2 last segments (the malt.* part) of the domain
            const match = domain.match(/^.*\.(malt\.[^.]+)$/);
            return match && match[1].replaceAll('.', '\\.');
        })
        .filter((domain) => !!domain);

    // any domain suffixed by one of these will be accepted (which therefore includes Insights domains)
    const uniqueDomainsToCheck = [...new Set(domainsToCheck)];
    const urlRe = new RegExp(`^https?:\\/\\/[^/]+\\.(${uniqueDomainsToCheck.join('|')})(\\/.*|\\?.*|#.*|$)`);
    return url.match(urlRe) !== null;
}

export const useSigninStore = defineStore('signin-store', () => {
    const {getHosts} = useHosts();
    const logger = useLogger();
    const {t} = useTranslation();

    const signinApi = new SigninApi(apiConfiguration);

    const pendingRequest = ref(false);

    const signinMethod = ref<SigninMethod>('EMAIL_AND_PASSWORD');
    const emailModel = ref('');
    const passwordModel = ref('');
    const cloudflareTokenModel = ref('');
    const authenticationError = ref<string | null>(null);
    const redirectTo = ref<string | null>(null);
    const ssoOrganizationName = ref<string | null>(null);
    const existingFreelancerSignupWorkflowEmail = ref<string | null>(null);

    watch([emailModel, passwordModel], () => {
        authenticationError.value = '';
        existingFreelancerSignupWorkflowEmail.value = null;
    });

    const {getRedirectTo} = useRedirectAfterSignin();

    async function getSigninMethodsRestrictions(redirect?: string): Promise<SigninMethodRestrictions | void> {
        try {
            const payload = {email: emailModel.value, redirect};
            const response = await signinApi.getSigninMethodRestrictions(payload);

            if (!response || response.emailAndPasswordAuthorized) {
                resetSigninMethod();
                return;
            }
            if (response.unclaimedComatchAccountRestriction?.authenticationUrl) {
                signinMethod.value = 'UNCLAIMED_COMATCH_ACCOUNT';
                redirectTo.value = response.unclaimedComatchAccountRestriction?.authenticationUrl;
                ssoOrganizationName.value = null;
            }
            if (response.ssoRestriction?.authenticationUrl) {
                signinMethod.value = 'SSO';
                redirectTo.value = response.ssoRestriction?.authenticationUrl;
                ssoOrganizationName.value = response.ssoRestriction?.organizationName as string;
            }
        } catch (e) {
            resetSigninMethod();
            logger.error(e);
        }
    }

    function resetSigninMethod() {
        signinMethod.value = 'EMAIL_AND_PASSWORD';
        redirectTo.value = null;
        ssoOrganizationName.value = null;
    }

    async function signinWithAuthenticationDetails(authenticationToken?: string): Promise<ResponseWrapper<CurrentSessionDetails>> {
        const email = emailModel.value;

        try {
            pendingRequest.value = true;
            authenticationError.value = null;

            const payload = {
                signinRequestWithAuthenticationDetails: {
                    email,
                    password: passwordModel.value,
                    authenticationToken,
                },
            };
            const data = await signinApi.signinWithAuthenticationDetails(payload, {headers: {'X-CF-TURNSTILE-TOKEN': cloudflareTokenModel.value}});

            redirectTo.value = getRedirectTo(data);
            return {data, error: null};
        } catch (e) {
            const error = e as FetchError;
            if (error.statusCode === 403) {
                authenticationError.value = error.getCauseData() as string;
                // no await is intended here, we want this check to be asynchronous and not block the update of the UI
                checkExistingSignupWorkflowEmail(email);
            } else {
                logger.error(error);
                pushVJoySnackbar({props: {message: t('registration.signin.server.error'), level: 'error'}});
            }
            return {data: null, error: e as FetchError};
        } finally {
            pendingRequest.value = false;
        }
    }

    async function urlUsesAValidDifferentHost(url: string): Promise<boolean> {
        if (!url.startsWith('http')) {
            // no need to fetch hosts in that case, we can answer right away
            return false;
        }

        const countries = await getHosts();
        return urlUsesOneOfDomains(url, countries);
    }

    async function checkExistingSignupWorkflowEmail(email: string): Promise<void> {
        try {
            await signinApi.checkExistingFreelancerSignupWorkflow({email});
            existingFreelancerSignupWorkflowEmail.value = email;
        } catch (e) {
            const error = e as FetchError;
            if (error.statusCode !== 404) {
                logger.error(error);
            }
        }
    }

    return {
        pendingRequest: readonlyState(pendingRequest),
        signinMethod: readonlyState(signinMethod),
        emailModel: readonlyState(emailModel),
        passwordModel: readonlyState(passwordModel),
        cloudflareTokenModel,
        authenticationError: readonlyState(authenticationError),
        existingFreelancerSignupWorkflowEmail: readonlyState(existingFreelancerSignupWorkflowEmail),
        redirectTo: readonlyState(redirectTo),
        ssoOrganizationName: readonlyState(ssoOrganizationName),
        getSigninMethodsRestrictions,
        resetSigninMethod,
        signinWithAuthenticationDetails,
        urlUsesAValidDifferentHost,
        checkExistingSignupWorkflowEmail,
    };
});
