/* tslint:disable */
/* eslint-disable */
/**
 * Registration API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SigninRequestWithAuthenticationDetails
 */
export interface SigninRequestWithAuthenticationDetails {
    /**
     * 
     * @type {string}
     * @memberof SigninRequestWithAuthenticationDetails
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof SigninRequestWithAuthenticationDetails
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof SigninRequestWithAuthenticationDetails
     */
    authenticationToken?: string;
}

/**
 * Check if a given object implements the SigninRequestWithAuthenticationDetails interface.
 */
export function instanceOfSigninRequestWithAuthenticationDetails(value: object): value is SigninRequestWithAuthenticationDetails {
    if (!('email' in value) || value['email'] === undefined) return false;
    if (!('password' in value) || value['password'] === undefined) return false;
    return true;
}

export function SigninRequestWithAuthenticationDetailsFromJSON(json: any): SigninRequestWithAuthenticationDetails {
    return SigninRequestWithAuthenticationDetailsFromJSONTyped(json, false);
}

export function SigninRequestWithAuthenticationDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): SigninRequestWithAuthenticationDetails {
    if (json == null) {
        return json;
    }
    return {
        
        'email': json['email'],
        'password': json['password'],
        'authenticationToken': json['authentication-token'] == null ? undefined : json['authentication-token'],
    };
}

export function SigninRequestWithAuthenticationDetailsToJSON(value?: SigninRequestWithAuthenticationDetails | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'email': value['email'],
        'password': value['password'],
        'authentication-token': value['authenticationToken'],
    };
}

