import {defineStore} from 'pinia';
import type {Ref} from 'vue';
import {ref} from 'vue';
import {useFeatureFlag} from '#imports';

type FFState<T extends string[]> = Record<T[number], boolean>;

/**
 * This function will create a Pinia store that can fetch and infer types of your feature flags
 * It's designed to be used at app start and persist during app lifecycle
 *
 * @param feats - array of feature-flags keys to fetch
 * @param [storeName="feature-flags"] - Override store name
 *
 * @usage
 *
 * ```ts
 *
 * const useFeatureFlagsStore = createFeatureFlagsStore(["client-navbar-v2", "my-foo-feature"]);
 *
 * const featureFlagsStore = useFeatureFlagsStore();
 * await featureFlagsStore.loadFeatureFlags();
 *
 *
 * const isMyFeatureEnabled = featureFlagsStore.features["client-navbar-v2"]; // Typed and reactive
 * ```
 *
 */
export function createFeatureFlagsStore<T extends string[] = []>(feats: [...T] = [] as unknown as [...T], storeName = 'feature-flags') {
    return defineStore(storeName, () => {
        const {fetchFeatureFlags} = useFeatureFlag();
        const features = ref<FFState<T>>({} as FFState<T>) as Ref<FFState<T>>;
        const hasBeenLoaded = ref(feats.length === 0);

        async function loadFeatureFlags() {
            if (hasBeenLoaded.value) {
                return;
            }

            const ff = await fetchFeatureFlags(feats);
            features.value = ff;
            hasBeenLoaded.value = true;
        }

        return {
            loadFeatureFlags,
            features,
            hasBeenLoaded,
        };
    });
}
