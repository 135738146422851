/* tslint:disable */
/* eslint-disable */
/**
 * Registration API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { mapValues } from '../runtime';
/**
 * Details of a restriction requiring the user to follow the Comatch "welcome" path.
 * @export
 * @interface UnclaimedComatchAccountRestriction
 */
export interface UnclaimedComatchAccountRestriction {
    /**
     * The URL where to redirect the user to claim their Comatch account.
     * @type {string}
     * @memberof UnclaimedComatchAccountRestriction
     */
    authenticationUrl: string;
}

/**
 * Check if a given object implements the UnclaimedComatchAccountRestriction interface.
 */
export function instanceOfUnclaimedComatchAccountRestriction(value: object): value is UnclaimedComatchAccountRestriction {
    if (!('authenticationUrl' in value) || value['authenticationUrl'] === undefined) return false;
    return true;
}

export function UnclaimedComatchAccountRestrictionFromJSON(json: any): UnclaimedComatchAccountRestriction {
    return UnclaimedComatchAccountRestrictionFromJSONTyped(json, false);
}

export function UnclaimedComatchAccountRestrictionFromJSONTyped(json: any, ignoreDiscriminator: boolean): UnclaimedComatchAccountRestriction {
    if (json == null) {
        return json;
    }
    return {
        
        'authenticationUrl': json['authenticationUrl'],
    };
}

export function UnclaimedComatchAccountRestrictionToJSON(value?: UnclaimedComatchAccountRestriction | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'authenticationUrl': value['authenticationUrl'],
    };
}

