/* tslint:disable */
/* eslint-disable */
/**
 * Registration API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { mapValues } from '../runtime';
import type { AccountInformation } from './';
import {
    AccountInformationFromJSON,
    AccountInformationFromJSONTyped,
    AccountInformationToJSON,
} from './';

/**
 * A successful response of /$app/api/user/current-session endpoints
 * @export
 * @interface CurrentSessionDetails
 */
export interface CurrentSessionDetails {
    /**
     * 
     * @type {AccountInformation}
     * @memberof CurrentSessionDetails
     */
    loggedInUser?: AccountInformation;
    /**
     * 
     * @type {string}
     * @memberof CurrentSessionDetails
     */
    pathWhereToRedirectUser?: string;
    /**
     * 
     * @type {string}
     * @memberof CurrentSessionDetails
     */
    visitorId: string;
    /**
     * 
     * @type {string}
     * @memberof CurrentSessionDetails
     */
    locale: string;
}

/**
 * Check if a given object implements the CurrentSessionDetails interface.
 */
export function instanceOfCurrentSessionDetails(value: object): value is CurrentSessionDetails {
    if (!('visitorId' in value) || value['visitorId'] === undefined) return false;
    if (!('locale' in value) || value['locale'] === undefined) return false;
    return true;
}

export function CurrentSessionDetailsFromJSON(json: any): CurrentSessionDetails {
    return CurrentSessionDetailsFromJSONTyped(json, false);
}

export function CurrentSessionDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): CurrentSessionDetails {
    if (json == null) {
        return json;
    }
    return {
        
        'loggedInUser': json['loggedInUser'] == null ? undefined : AccountInformationFromJSON(json['loggedInUser']),
        'pathWhereToRedirectUser': json['pathWhereToRedirectUser'] == null ? undefined : json['pathWhereToRedirectUser'],
        'visitorId': json['visitorId'],
        'locale': json['locale'],
    };
}

export function CurrentSessionDetailsToJSON(value?: CurrentSessionDetails | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'loggedInUser': AccountInformationToJSON(value['loggedInUser']),
        'pathWhereToRedirectUser': value['pathWhereToRedirectUser'],
        'visitorId': value['visitorId'],
        'locale': value['locale'],
    };
}

