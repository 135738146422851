import {navigateTo, useAsyncData, useAuthFeatureFlags, useCookie, useUniversalFetch, useUniversalPost} from '#imports';
import type {NuxtError} from 'nuxt/app';
import {FetchError} from 'ofetch';
import {defineStore} from 'pinia';
import type {Ref} from 'vue';
import {computed, ref, watch} from 'vue';
import type {ImmersionOwnerRequest} from '../composables/useImmersionMode';
import {useIsMountedAppSession} from '../composables/useIsMountedAppSession';
import type {CurrentSessionDetails, IdentityEnforcementRequest, IdentityEnforcementResponse} from '../models/IdentityEnforcement';
import type {LightUser, ProductFeature, User, UserIdentityType} from '../models/User';
import type {RequiredRoles} from '../models/RequiredRoles';

/**
 * ## Store : *useAuth*
 *
 * Retrieve the store related to authentication and all this methods
 * @public-api
 *
 */
export const useAuth = defineStore('auth', () => {
    // Composables
    const isMountedAppSession = useIsMountedAppSession();
    const ffStore = useAuthFeatureFlags();

    // Allow for propagating the identity used by the page with any calls made to the server.
    // See plugin updateSelectedIdentityCookieOnPageFocus.client.ts
    const selectedIdentityCookie = useCookie<string>('hopsi', {path: '/'});

    // This cookie is used by redirect-user-to-workflow-in-progress.global.ts
    // to determine if it should verify whether a workflow is in progress.
    const pathOfWorkflowInProgressCookie = useCookie<string | null>('path-of-mandatory-workflow-likely-in-progress');

    // Refs
    const user: Ref<User | null> = ref(null);
    const loggedIn: Ref<boolean> = ref(!!user.value);
    const userLocale: Ref<string | undefined> = ref();
    const userVisitorId: Ref<string | undefined> = ref();
    const hasAlreadyCheckIfLogged: Ref<boolean> = ref(false);
    const hasAlreadyCurrentSession: Ref<boolean> = ref(false);

    // Computed values
    const isUmbrellaCompany = computed<boolean>(() => user.value?.extraInformation?.umbrellaCompany === 'true');

    const getSelectedIdentity = computed(() => {
        return user.value?.identities?.find((identity) => identity.selected);
    });

    const currentIdentityIsClient = computed(() => {
        const selectedIdentity = getSelectedIdentity.value;
        return selectedIdentity?.identityType?.toLowerCase() === 'client';
    });

    const currentIdentityIsFreelance = computed(() => {
        const selectedIdentity = getSelectedIdentity.value;
        return selectedIdentity?.identityType?.toLowerCase() === 'freelance';
    });

    const currentIdentityIsUndefined = computed(() => {
        const selectedIdentity = getSelectedIdentity.value;
        return selectedIdentity?.identityType?.toLowerCase() === 'undefined';
    });

    const hasProfile = computed(() => {
        return user.value?.identities?.find((identity) => identity.identityType.toLowerCase() === 'freelance') != null;
    });

    // ensure the cookie is updated when the API returns us a new user/identity
    // (for instance in case of sudosu, refresh, etc.)
    watch(user, updateSelectedIdentityCookie);

    function updateSelectedIdentityCookie() {
        // We don't want to update the cookie client-side if another tab has the focus,
        // as it can be using another identity.
        if (user.value && (import.meta.server || document.hasFocus())) {
            if (selectedIdentityCookie.value !== user.value.selectedIdentity) {
                selectedIdentityCookie.value = user.value.selectedIdentity;
            }
        }
    }

    // Actions
    async function login(username: string, password: string): Promise<void> {
        const response = await useUniversalPost<User>('/api/user/signin', {
            body: {
                username,
                password,
            },
        });

        loggedIn.value = true;
        user.value = response;
    }

    async function logout(redirectUrl: string = '/signin'): Promise<void> {
        await useUniversalPost('/api/logout');
        sessionStorage.clear();
        localStorage.clear();
        await navigateTo(redirectUrl, {external: true});
    }

    function hasEnoughPrivilege(requiredRoles: RequiredRoles): boolean {
        if (Array.isArray(requiredRoles)) {
            const noPrivilegeNeeded = requiredRoles.length === 0;
            const hasAllRequiredRoles = requiredRoles.every((role) => (user.value?.roles || []).includes(role));

            return noPrivilegeNeeded || hasAllRequiredRoles;
        } else {
            if (requiredRoles.or.length === 0) {
                throw new Error('"requiredRoles.or" must not be empty');
            }
            return requiredRoles.or.some((subConfig) => hasEnoughPrivilege(subConfig));
        }
    }

    function hasCorrectIdentity(requiredIdentity: UserIdentityType | 'ANY'): boolean {
        if (requiredIdentity === 'ANY') {
            return true;
        } else {
            const selectedIdentity = user.value?.identities?.find((identity) => identity.id === user.value?.selectedIdentity);
            if (selectedIdentity) {
                return selectedIdentity.identityType === requiredIdentity;
            }
            return false;
        }
    }

    async function getImmersionOwner(request: ImmersionOwnerRequest): Promise<LightUser | undefined> {
        let userInImmersion;
        try {
            if (request.type === 'IDENTITY_ID') {
                userInImmersion = await useUniversalFetch<LightUser>(`/api/user/identity/${request.id}`);
            } else if (request.type === 'ACCOUNT_ID') {
                userInImmersion = await useUniversalFetch<LightUser>(`/api/user/account/${request.id}`);
            }
        } catch (error) {
            // useLogger not available in instance
            /* eslint-disable no-console */
            console.error(error);
        }
        return userInImmersion;
    }

    async function switchIdentity(identity: string): Promise<void> {
        const identityIdBeforeEnforcement = user.value?.selectedIdentity;

        const response = await useUniversalPost<User>(`/api/user/signin/identity?identityId=${identity}`);
        user.value = response;

        if (user.value.selectedIdentity !== identityIdBeforeEnforcement) {
            advertiseEnforcedIdentity(user.value.selectedIdentity);
        }
    }

    function isThisIdentityInImmersionMode(identityId: string) {
        if (!identityId) {
            return false;
        }
        if (!user.value) {
            throw new Error("isThisIdentityInImmersionMode can't be called when auth store is empty");
        }
        return !!(user.value!.selectedIdentity !== identityId && user.value!.roles?.includes('ROLE_ADMIN'));
    }

    async function impersonate(accountId: string): Promise<void> {
        const response = await useUniversalPost<User>(`/api/user/sudosu?accountId=${accountId}`);
        user.value = response;
    }

    async function unsudosu(): Promise<void> {
        const response = await useUniversalFetch<User>(`/api/user/unsudosu`, {method: 'POST'});
        user.value = response;
    }

    async function refresh(): Promise<void> {
        try {
            const response = await useUniversalFetch<User>('/api/user/me');
            user.value = response;
            loggedIn.value = true;
        } catch (error) {
            user.value = null;
            loggedIn.value = false;
        }
    }

    function isCompanySuspended(): boolean {
        return user.value?.extraInformation?.companySuspended === 'true';
    }

    function isCarriedFreelancer(): boolean {
        return user.value?.extraInformation?.carriedFreelancer === 'true';
    }

    function isFreelancerApplicationInProgress(): boolean {
        return user.value?.extraInformation?.freelancerApplicationInProgress === 'true';
    }

    async function isLogged(): Promise<boolean> {
        // If the user is not logged, we ask the server. In this situation, it's possible the user refreshed the page
        // but the session is still valid.
        // this situation only happens on secured page, if the user is not logged
        if (!loggedIn.value) {
            try {
                user.value = await useUniversalFetch<User>('/api/user/me');
                loggedIn.value = true;
            } catch (error: unknown) {
                if (error instanceof FetchError) {
                    // for "reasons", we return a 403 status when we should return a 401, but let's handle both to let us fix that in the future
                    if (error.status !== 401 && error.status !== 403) {
                        // useLogger not available in instance
                        /* eslint-disable no-console */
                        console.error('No user found', error);
                    }
                }
            }
        }
        return loggedIn.value;
    }

    async function loginOnce(): Promise<boolean> {
        if (!loggedIn.value && !hasAlreadyCheckIfLogged.value) {
            await isLogged();
            hasAlreadyCheckIfLogged.value = true;
        }
        return loggedIn.value;
    }

    async function checkIfLoggedOrIfNeedingRedirect(): Promise<{
        isLogged: boolean;
        pathWhereToRedirectUser?: string;
    }> {
        if (!hasAlreadyCurrentSession.value) {
            const url = '/api/user/current-session';

            const {data, error} = await useAsyncData('current-user-session', async () => {
                hasAlreadyCurrentSession.value = true;
                return await useUniversalFetch<CurrentSessionDetails>(url);
            });

            if (error.value) {
                if (error.value instanceof FetchError) {
                    // useLogger not available in instance
                    /* eslint-disable no-console */
                    console.error('No user found', error.value);
                }
                return {
                    isLogged: loggedIn.value,
                };
            }

            const {loggedInUser, pathWhereToRedirectUser} = data.value!;
            user.value = loggedInUser || null;
            loggedIn.value = user.value != null;
            pathOfWorkflowInProgressCookie.value = pathWhereToRedirectUser || null;

            if (data.value) {
                const {visitorId, locale} = data.value;
                if (locale) {
                    userLocale.value = locale;
                }
                if (visitorId) {
                    userVisitorId.value = visitorId;
                }

                if (!visitorId || !locale) {
                    // useLogger not available in instance
                    /* eslint-disable no-console */
                    console.error('Unable to get visitorId or locale while fetching current session, got:', data.value);
                }
            }
        }

        return {
            isLogged: loggedIn.value,
            pathWhereToRedirectUser: pathOfWorkflowInProgressCookie.value || undefined,
        };
    }

    async function tryToEnforceIdentity(enforcementRequest: IdentityEnforcementRequest): Promise<{
        isLogged: boolean;
        hasValidIdentity: boolean;
        pathWhereToRedirectUser?: string;
    }> {
        const identityIdBeforeEnforcement = user.value?.selectedIdentity;

        const url = '/api/user/identity-enforcement-request?v2';

        let data = ref<IdentityEnforcementResponse | null>();
        let error = ref<NuxtError<unknown> | null>();

        if (isMountedAppSession()) {
            try {
                // Avoid running useAsyncData if the app is already mounted, preventing warnings
                const fetchData = await useUniversalFetch<IdentityEnforcementResponse>(url, {method: 'POST', body: enforcementRequest});
                data.value = fetchData;
            } catch (e) {
                error.value = e as NuxtError<unknown>;
            }
        } else {
            ({data, error} = await useAsyncData('identity-enforcement-request-v2', () =>
                useUniversalFetch<IdentityEnforcementResponse>(url, {
                    method: 'POST',
                    body: enforcementRequest,
                }),
            ));
        }
        if (error.value) {
            if (error.value instanceof FetchError) {
                // useLogger not available in instance
                /* eslint-disable no-console */
                console.error('Unexpected error when enforcing identity', error);
            }

            return {
                isLogged: loggedIn.value,
                hasValidIdentity: true,
            };
        }

        const enforcementResponse = data.value!;

        pathOfWorkflowInProgressCookie.value = enforcementResponse.currentSession.pathWhereToRedirectUser || null;

        const loggedInUser = enforcementResponse.currentSession.loggedInUser;
        if (loggedInUser) {
            user.value = loggedInUser;
            loggedIn.value = true;

            if (user.value!.selectedIdentity !== identityIdBeforeEnforcement) {
                advertiseEnforcedIdentity(user.value!.selectedIdentity);
            }

            return {
                isLogged: true,
                hasValidIdentity: enforcementResponse.identitySuccessfullyEnforced,
                pathWhereToRedirectUser: enforcementResponse.currentSession.pathWhereToRedirectUser,
            };
        } else {
            user.value = null;
            loggedIn.value = false;

            return {
                isLogged: false,
                hasValidIdentity: false,
                pathWhereToRedirectUser: enforcementResponse.currentSession.pathWhereToRedirectUser,
            };
        }
    }

    /**
     * Publish an event to tell any interested component when an use identity has been enforced, so
     * that this component can update its knowledge and maybe re-render, etc.
     *
     * A typical use for it would be a situation like the following:
     * An user is currently using Malt as a freelancer (i.e. their freelancer identity is currently
     * "selected"), and they receive an email asking them to validate a project, with a direct link
     * to said project's page. Such an email would clearly concern one of their client identities,
     * and therefore not the selected freelancer one. When accessing the page, that page would
     * enforce the right identity using the auth middleware. But the navbar could have already been
     * (or be in the process of being) displayed for the freelancer identity. Therefore, we would
     * like to update the navbar to display the enforced client identity instead.
     *
     * This function does nothing server-side. Indeed, it's unclear if/how we could somehow publish an
     * event to be processed by another component which has potentially be already rendered.
     *
     * So let's focus on client-side for now, and maybe in the future we will try to make the client
     * rerender what's needed after a first server-rendering, by somehow "storing" the event emitted
     * below for the client to consume it.
     *
     * Speaking about storing, the below implementation uses a custom event but not a store for several
     * reasons:
     * - We really want to only react to a backend response enforcing an identity.
     * - We already have other mechanisms in place front-side to keep track of what identity
     *   should be used (for instance the auth store, and the "hopsi" cookie, both in our
     *   nuxt-auth-module).
     * - We should take care not to create a situation where different views of the world are stored.
     * - The navbar for example should become a micro-frontend soon, in which case we wouldn't have
     *   much other options to communicate with it.
     *
     * How to use:
     * ```
     * window.addEventListener('user-identity-enforced', (evt: Event) => {
     *     const e = evt as CustomEvent;
     *     // do something with: e.detail.enforcedIdentityId
     * });
     * ```
     */
    function advertiseEnforcedIdentity(enforcedIdentityId: string) {
        if (import.meta.server) {
            return;
        }

        window.dispatchEvent(
            new CustomEvent('user-identity-enforced', {
                detail: {enforcedIdentityId},
            }),
        );
    }

    function currentIdentityHasAccessTo(productFeature: ProductFeature): boolean {
        return getSelectedIdentity.value?.features.includes(productFeature) || false;
    }

    return {
        // A-Z refs
        currentIdentityIsClient,
        currentIdentityIsFreelance,
        currentIdentityIsUndefined,
        getSelectedIdentity,
        hasAlreadyCheckIfLogged,
        hasAlreadyCurrentSession,
        hasProfile,
        isUmbrellaCompany,
        loggedIn,
        user,
        userLocale,
        userVisitorId,

        // A-Z functions
        checkIfLoggedOrIfNeedingRedirect,
        currentIdentityHasAccessTo,
        getImmersionOwner,
        hasCorrectIdentity,
        hasEnoughPrivilege,
        impersonate,
        isCarriedFreelancer,
        isCompanySuspended,
        isFreelancerApplicationInProgress,
        isLogged,
        isThisIdentityInImmersionMode,
        login,
        loginOnce,
        logout,
        refresh,
        switchIdentity,
        tryToEnforceIdentity,
        unsudosu,
        updateSelectedIdentityCookie,
    };
});
