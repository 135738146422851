<template>
    <NuxtPage />
</template>

<script setup lang="ts">
    import {use3rdParties, useAsyncData, useHead, useHostSettings, useHTMLAttrsLang, useScripts, useSeoMeta, useTranslation} from '#imports';

    use3rdParties();
    useHTMLAttrsLang();

    const {data, error} = await useAsyncData('host-settings', () => useHostSettings());
    if (!error.value) {
        const script = useScripts(data.value);
        useHead({script});
    }

    const {t} = useTranslation();
    const description = t('seo.malt.meta.description');

    useSeoMeta({
        description,
        robots: 'noindex',
    });
</script>
