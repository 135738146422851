/* tslint:disable */
/* eslint-disable */
/**
 * Registration API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { mapValues } from '../runtime';
import type { AccountInformationIdentity } from './';
import {
    AccountInformationIdentityFromJSON,
    AccountInformationIdentityFromJSONTyped,
    AccountInformationIdentityToJSON,
} from './';
import type { AccountPhoto } from './';
import {
    AccountPhotoFromJSON,
    AccountPhotoFromJSONTyped,
    AccountPhotoToJSON,
} from './';

/**
 * A successful response of /$app/api/user/me endpoints
 * @export
 * @interface AccountInformation
 */
export interface AccountInformation {
    /**
     * 
     * @type {string}
     * @memberof AccountInformation
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AccountInformation
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof AccountInformation
     */
    email: string;
    /**
     * 
     * @type {Set<string>}
     * @memberof AccountInformation
     */
    roles: Set<string>;
    /**
     * 
     * @type {string}
     * @memberof AccountInformation
     */
    selectedIdentity?: string;
    /**
     * 
     * @type {Array<AccountInformationIdentity>}
     * @memberof AccountInformation
     */
    identities: Array<AccountInformationIdentity>;
    /**
     * 
     * @type {boolean}
     * @memberof AccountInformation
     */
    platformAdmin: boolean;
    /**
     * 
     * @type {AccountPhoto}
     * @memberof AccountInformation
     */
    photo?: AccountPhoto;
    /**
     * 
     * @type {boolean}
     * @memberof AccountInformation
     */
    impersonated: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccountInformation
     */
    impersonatedBy?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AccountInformation
     */
    connectedThroughSso: boolean;
    /**
     * 
     * @type {object}
     * @memberof AccountInformation
     */
    extraInformation?: object;
}

/**
 * Check if a given object implements the AccountInformation interface.
 */
export function instanceOfAccountInformation(value: object): value is AccountInformation {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('username' in value) || value['username'] === undefined) return false;
    if (!('email' in value) || value['email'] === undefined) return false;
    if (!('roles' in value) || value['roles'] === undefined) return false;
    if (!('identities' in value) || value['identities'] === undefined) return false;
    if (!('platformAdmin' in value) || value['platformAdmin'] === undefined) return false;
    if (!('impersonated' in value) || value['impersonated'] === undefined) return false;
    if (!('connectedThroughSso' in value) || value['connectedThroughSso'] === undefined) return false;
    return true;
}

export function AccountInformationFromJSON(json: any): AccountInformation {
    return AccountInformationFromJSONTyped(json, false);
}

export function AccountInformationFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountInformation {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'username': json['username'],
        'email': json['email'],
        'roles': json['roles'],
        'selectedIdentity': json['selectedIdentity'] == null ? undefined : json['selectedIdentity'],
        'identities': ((json['identities'] as Array<any>).map(AccountInformationIdentityFromJSON)),
        'platformAdmin': json['platformAdmin'],
        'photo': json['photo'] == null ? undefined : AccountPhotoFromJSON(json['photo']),
        'impersonated': json['impersonated'],
        'impersonatedBy': json['impersonatedBy'] == null ? undefined : json['impersonatedBy'],
        'connectedThroughSso': json['connectedThroughSso'],
        'extraInformation': json['extraInformation'] == null ? undefined : json['extraInformation'],
    };
}

export function AccountInformationToJSON(value?: AccountInformation | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'username': value['username'],
        'email': value['email'],
        'roles': Array.from(value['roles'] as Set<any>),
        'selectedIdentity': value['selectedIdentity'],
        'identities': ((value['identities'] as Array<any>).map((v) => AccountInformationIdentityToJSON(v))),
        'platformAdmin': value['platformAdmin'],
        'photo': AccountPhotoToJSON(value['photo']),
        'impersonated': value['impersonated'],
        'impersonatedBy': value['impersonatedBy'],
        'connectedThroughSso': value['connectedThroughSso'],
        'extraInformation': value['extraInformation'],
    };
}

