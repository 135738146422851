import {useLogger, useRequestURL} from '#imports';
import type {CurrentSessionDetails} from '@api';

export function useRedirectAfterSignin() {
    const logger = useLogger();
    const currentUrl = useRequestURL();

    function getRedirectTo(sessionDetails: CurrentSessionDetails) {
        if (sessionDetails.pathWhereToRedirectUser) {
            return sessionDetails.pathWhereToRedirectUser;
        }

        const redirectParam = currentUrl.searchParams.get('redirect');
        if (redirectParam) {
            return redirectParam;
        }

        const loggedInUser = sessionDetails.loggedInUser!;
        const selectedIdentityType = loggedInUser.identities?.find((identity) => identity.id === loggedInUser.selectedIdentity)?.identityType;
        switch (selectedIdentityType) {
            case 'CLIENT': {
                return '/project-client/find-freelancers';
            }
            case 'FREELANCE': {
                return '/dashboard/freelancer';
            }
            case 'UNDEFINED': {
                return null;
            }
            default: {
                logger.error(`Unexpected identity type: ${selectedIdentityType}`);
                return null;
            }
        }
    }

    return {
        getRedirectTo,
    };
}
