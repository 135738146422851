import type {NavigateToOptions} from '#app/composables/router';
import {withQuery} from 'ufo';
import type {NavigationFailure, RouteLocationRaw} from 'vue-router';
import {navigateTo} from '#imports';

type SafelyNavigateToOptions = Omit<NavigateToOptions, 'external'> & {
    externalToThisApp?: boolean;
    externalToThisHost?: boolean;
};

function startsWithScheme(url: string) {
    const schemeRe = /^[a-z0-9.+-]+:(\/\/)?.*/i;
    return schemeRe.test(url);
}

function toUrlString(to: RouteLocationRaw) {
    // noinspection SuspiciousTypeOfGuard
    if (typeof to === 'string') {
        return to;
    }
    const path = 'path' in to ? to.path : '/';
    const queryParams = to.query ?? {};
    const hash = to.hash ?? '';
    return withQuery(path as string, queryParams) + hash;
}

function isEvilRedirection(url: string) {
    // Please, before changing anything here, read carefully that:
    // https://github.com/swisskyrepo/PayloadsAllTheThings/tree/master/Open%20Redirect
    // This is a list of common payload to bypass filters/sanitization in redirection URL
    return startsWithScheme(url) || url.startsWith('//') || url.startsWith('.') || url.includes('@');
}

function sanitizedAndWithoutHostAndScheme(urlString: string) {
    if (startsWithScheme(urlString)) {
        const url = new URL(urlString.replace(/^\//, ''));
        const urlFromPath = url.pathname + url.search + url.hash;
        return urlFromPath.startsWith('/') ? urlFromPath : `/${urlFromPath}`;
    }

    const urlFromPath = urlString.replace(/^\.+/, '').replaceAll(/\/+/g, '/');
    return urlFromPath.startsWith('/') ? urlFromPath : `/${urlFromPath}`;
}

/**
 * A drop-in replacement for Nuxt's navigateTo with slightly different options, to make it clear:
 * <ul>
 * <li>when we don't want to leave the current Nuxt application (no option)</li>
 * <li>when we only want to navigate to a different Nuxt application of the platform - meaning on
 * the same host (<tt>{externalToThisApp: true}</tt>)</li>
 * <li>or when we accept navigating to another host (<tt>{externalToThisHost: true}</tt>)</li>
 * </ul>
 * This helps to prevent any <a href="https://github.com/swisskyrepo/PayloadsAllTheThings/tree/master/Open%20Redirect">
 * Open URL Redirection</a>.
 *
 * Note: the issue with Nuxt's navigateTo is that in case we don't call it with `{external: true}`,
 * it will only accept paths handled by the current application's router, or will act as if these
 * paths should be handled by it.
 * For example, passing it /some-path-handled-by-another-app will result in it redirecting the
 * user to /app-base-url/some-path-handled-by-another-app (while emitting a warning in the console
 * when in dev mode). This makes sense given how a SPA works, but we need a way to leave the
 * application while still not allowing leaving the current host.
 *
 * @param to ('/' will be used when not provided)
 * @param options
 */
export const safelyNavigateTo = (to: RouteLocationRaw | undefined | null, options?: SafelyNavigateToOptions): ReturnType<typeof navigateTo> => {
    if (to && !options?.externalToThisHost) {
        const urlString = toUrlString(to);
        if (isEvilRedirection(urlString)) {
            // Instead of throwing an error, we sanitize the URL. This preserves a working
            // navigation, should the URL contain one of our host names.
            // This is the same strategy we use back-end side, in com.hopwork.httprequestutils.RedirectSecurityUtils.computeRedirectUrl
            // (see https://gitlab.com/maltcommunity/malt/apps/malt/-/blob/6f226d9d3927663f65fd7ec36da3ec20d7869118/lib-platform/httprequestutils/src/main/java/com/hopwork/httprequestutils/RedirectSecurityUtils.java#L12-15)
            to = sanitizedAndWithoutHostAndScheme(urlString);
        }
    }

    const navigateToOptions: NavigateToOptions | undefined =
        options &&
        Object.assign({}, options, {
            external: options?.externalToThisApp || options?.externalToThisHost,
        });
    return navigateTo(to, navigateToOptions);
};
