import type {PostProcessorModule, TOptions} from 'i18next';

const NAME = 'malt-nuxt-i18n-debug';

const MaltNuxtI18nDebugPostProcessor: PostProcessorModule = {
    type: 'postProcessor',
    name: NAME,
    process(value: string, key: string, options: TOptions, translator: any): string {
        return `${key}`;
    },
};

export default MaltNuxtI18nDebugPostProcessor;
