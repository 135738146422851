import {showError, defineNuxtPlugin, useUniversalPost} from '#imports';
import type {FetchError} from 'ofetch';

export default defineNuxtPlugin((nuxt) => {
    nuxt.hook('page:finish', async () => {
        try {
            await useUniversalPost<any>('/cookie-verify', {baseURL: '/'});
        } catch (e) {
            const error = e as FetchError;
            if (error.status === 403) {
                showError({message: '(cookie-verifier) Cookie are not working properly', data: {type: 'cookie-verify'}});
            }
        }
    });
});
