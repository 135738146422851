import {defineNuxtPlugin, onNuxtReady} from '#imports';

/**
 * Used for TAs to wait for app to be interactive.
 *
 * See https://nuxt.com/docs/api/advanced/hooks.
 */
export default defineNuxtPlugin(() => {
    /**
     * Add a data-nuxt-page-finish attribute to html element when app is fully hydrated
     */
    onNuxtReady(() => {
        document.documentElement.setAttribute('data-nuxt-page-finish', '');
    });
});
