import {useUniversalFetch} from '#imports';
import type {Host, Language} from '../types';

function addLanguage(map: Record<string, string>, language: Language): Record<string, string> {
    map[language.locale.replace('_', '-').toLowerCase()] = language.host;
    return map;
}

function groupHostsByLocales(hosts: Host[]): Record<string, string> {
    return hosts.flatMap((host) => host.languages).reduce(addLanguage, {});
}

function getDomains(hosts: Host[]): string[] {
    const domains: string[] = [];

    for (const host of hosts) {
        for (const language of host.languages) {
            domains.push(language.host);
        }
    }

    return domains;
}

function getHosts(): Promise<Host[]> {
    return useUniversalFetch<Host[]>('/api/supported-host/l10n/hosts')
        .then((response) => response)
        .catch(() => []);
}

function getHostsByLocales(): Promise<Record<string, string>> {
    return getHosts().then(groupHostsByLocales);
}

export function useHosts() {
    return {
        getHostsByLocales,
        getHosts,
        getDomains,
    };
}
