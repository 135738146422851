import payload_plugin_qbu4MAJQT8 from "/tmpfs/builds/maltcommunity/malt/apps/malt/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescript@5.5.4_vue@3.5.13_typescript@5.5.4__/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_gsUzX5uUAx from "/tmpfs/builds/maltcommunity/malt/apps/malt/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.4.1_@types+node@20.14.2_db0@0.2.1_encoding@0.1.13_eslint@8.35.0_oj6kj6bfhtz7e2vdxyjb7g7era/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_f9t0ddELVr from "/tmpfs/builds/maltcommunity/malt/apps/malt/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.4.1_@types+node@20.14.2_db0@0.2.1_encoding@0.1.13_eslint@8.35.0_oj6kj6bfhtz7e2vdxyjb7g7era/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_mQCfdgQlpI from "/tmpfs/builds/maltcommunity/malt/apps/malt/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.4.1_@types+node@20.14.2_db0@0.2.1_encoding@0.1.13_eslint@8.35.0_oj6kj6bfhtz7e2vdxyjb7g7era/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_O1dtlIJXcY from "/tmpfs/builds/maltcommunity/malt/apps/malt/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.4.1_@types+node@20.14.2_db0@0.2.1_encoding@0.1.13_eslint@8.35.0_oj6kj6bfhtz7e2vdxyjb7g7era/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_jwU3XXoxCD from "/tmpfs/builds/maltcommunity/malt/apps/malt/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.4.1_@types+node@20.14.2_db0@0.2.1_encoding@0.1.13_eslint@8.35.0_oj6kj6bfhtz7e2vdxyjb7g7era/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_kLgdPcR4bZ from "/tmpfs/builds/maltcommunity/malt/apps/malt/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.4.1_@types+node@20.14.2_db0@0.2.1_encoding@0.1.13_eslint@8.35.0_oj6kj6bfhtz7e2vdxyjb7g7era/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_OLpAAonv83 from "/tmpfs/builds/maltcommunity/malt/apps/malt/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.4.1_@types+node@20.14.2_db0@0.2.1_encoding@0.1.13_eslint@8.35.0_oj6kj6bfhtz7e2vdxyjb7g7era/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_Ez5oX1gKCV from "/tmpfs/builds/maltcommunity/malt/apps/malt/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescript@5.5.4_vue@3.5.13_typescript@5.5.4__/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/tmpfs/builds/maltcommunity/malt/apps/malt/registration/registration-front/.nuxt/components.plugin.mjs";
import prefetch_client_vPH5UQDIWq from "/tmpfs/builds/maltcommunity/malt/apps/malt/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.4.1_@types+node@20.14.2_db0@0.2.1_encoding@0.1.13_eslint@8.35.0_oj6kj6bfhtz7e2vdxyjb7g7era/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import joy_vue_light_plugin_cWITl84aX4 from "/tmpfs/builds/maltcommunity/malt/apps/malt/lib-nuxt/nuxt-joy-module/src/runtime/joy-vue-light-plugin.ts";
import plugin_SklQJ8ieez from "/tmpfs/builds/maltcommunity/malt/apps/malt/lib-nuxt/nuxt-joy-module/src/runtime/plugins/deprecated-webcomponents/plugin.ts";
import _3_flushStoredKeys_client_w5Nc4aQt7V from "/tmpfs/builds/maltcommunity/malt/apps/malt/lib-nuxt/nuxt-i18n-module/src/runtime/plugins/3.flushStoredKeys.client.ts";
import load_feature_flags_KvAoyRoMbK from "/tmpfs/builds/maltcommunity/malt/apps/malt/lib-nuxt/nuxt-auth-module/src/runtime/plugins/load-feature-flags.ts";
import cookie_verifier_client_cgqgefCYDD from "/tmpfs/builds/maltcommunity/malt/apps/malt/lib-nuxt/nuxt-auth-module/src/runtime/plugins/cookie-verifier.client.ts";
import is_logged_GAF6t6e4TS from "/tmpfs/builds/maltcommunity/malt/apps/malt/lib-nuxt/nuxt-auth-module/src/runtime/plugins/is-logged.ts";
import sentry_client_uoo48Xb7hs from "/tmpfs/builds/maltcommunity/malt/apps/malt/lib-nuxt/nuxt-sentry-module/src/runtime/plugins/sentry.client.ts";
import app_hydrated_client_2zp3ZaRlHI from "/tmpfs/builds/maltcommunity/malt/apps/malt/lib-nuxt/nuxt-core-module/src/runtime/plugins/app-hydrated.client.ts";
import logger_C2DvR1HwTQ from "/tmpfs/builds/maltcommunity/malt/apps/malt/lib-nuxt/nuxt-logger-module/src/runtime/plugins/logger.ts";
import _0_visitorid_HKHmeusv8p from "/tmpfs/builds/maltcommunity/malt/apps/malt/lib-nuxt/nuxt-tracking-module/src/runtime/plugins/0.visitorid.ts";
import _1_pageview_client_zp4O983bAM from "/tmpfs/builds/maltcommunity/malt/apps/malt/lib-nuxt/nuxt-tracking-module/src/runtime/plugins/1.pageview.client.ts";
import _2_datalayer_EXbqlBQ9rc from "/tmpfs/builds/maltcommunity/malt/apps/malt/lib-nuxt/nuxt-tracking-module/src/runtime/plugins/2.datalayer.ts";
import _3_hotjar_client_JKmg1vGle0 from "/tmpfs/builds/maltcommunity/malt/apps/malt/lib-nuxt/nuxt-tracking-module/src/runtime/plugins/3.hotjar.client.ts";
import update_selected_identity_cookie_on_page_focus_client_Oe45p4Zpol from "/tmpfs/builds/maltcommunity/malt/apps/malt/lib-nuxt/nuxt-auth-module/src/runtime/plugins/update-selected-identity-cookie-on-page-focus.client.ts";
import _1_i18next_lazy_MtjTLccoBu from "/tmpfs/builds/maltcommunity/malt/apps/malt/lib-nuxt/nuxt-i18n-module/src/runtime/plugins/1.i18next.lazy.ts";
export default [
  payload_plugin_qbu4MAJQT8,
  revive_payload_client_gsUzX5uUAx,
  unhead_f9t0ddELVr,
  router_mQCfdgQlpI,
  payload_client_O1dtlIJXcY,
  navigation_repaint_client_jwU3XXoxCD,
  check_outdated_build_client_kLgdPcR4bZ,
  chunk_reload_client_OLpAAonv83,
  plugin_vue3_Ez5oX1gKCV,
  components_plugin_KR1HBZs4kY,
  prefetch_client_vPH5UQDIWq,
  joy_vue_light_plugin_cWITl84aX4,
  plugin_SklQJ8ieez,
  _3_flushStoredKeys_client_w5Nc4aQt7V,
  load_feature_flags_KvAoyRoMbK,
  cookie_verifier_client_cgqgefCYDD,
  is_logged_GAF6t6e4TS,
  sentry_client_uoo48Xb7hs,
  app_hydrated_client_2zp3ZaRlHI,
  logger_C2DvR1HwTQ,
  _0_visitorid_HKHmeusv8p,
  _1_pageview_client_zp4O983bAM,
  _2_datalayer_EXbqlBQ9rc,
  _3_hotjar_client_JKmg1vGle0,
  update_selected_identity_cookie_on_page_focus_client_Oe45p4Zpol,
  _1_i18next_lazy_MtjTLccoBu
]