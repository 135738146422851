import type {PostProcessorModule, TOptions} from 'i18next';

const NAME = 'malt-nuxt-i18n-log';

const MaltNuxtI18nLogPostProcessor: PostProcessorModule = {
    type: 'postProcessor',
    name: NAME,
    process(value: string, key: string, options: TOptions, translator: any): string {
        console.debug(`useTranslation: key='${key}'`);

        return value;
    },
};

export default MaltNuxtI18nLogPostProcessor;
