import {defineNuxtPlugin, useAsyncData, useAuth} from '#imports';
import type {Pinia} from 'pinia';

export default defineNuxtPlugin(async (nuxtApp) => {
    const $pinia = nuxtApp.$pinia as Pinia;
    const {isLogged} = useAuth($pinia);

    await useAsyncData(
        'isLogged',
        async () => {
            return {
                isLogged: await isLogged(),
            };
        },
        {dedupe: 'defer'},
    );
});
