import {useLogger, useUniversalFetch, useUniversalPut} from '#imports';

async function isFeatureEnabled(featureFlagId: string): Promise<boolean> {
    // const logger = useLogger();
    const endpoint = `/api/feature-flipping/${featureFlagId}`;

    let enabled = false;
    try {
        enabled = await useUniversalFetch<boolean>(endpoint);
    } catch (e) {
        // logger.error(`Error while checking FF "${featureFlagId}" activation.`);
    }
    return enabled;
}

async function isFeatureEnabledForHost(featureFlagId: string): Promise<boolean> {
    // const logger = useLogger();
    const endpoint = `/api/feature-flipping/host/${featureFlagId}`;

    let enabled = false;
    try {
        enabled = await useUniversalFetch<boolean>(endpoint);
    } catch (e) {
        // logger.error(`Error while checking FF "${featureFlagId}" activation.`);
    }
    return enabled;
}

type FFState<T extends string[]> = Record<T[number], boolean>;

export class FeatureFlag {
    feature: string;
    isEnabledForUser: boolean;
    isCanBeModifiedForUser: boolean;

    constructor(feature: string, isEnabledForUser: boolean, isCanBeModifiedForUser: boolean) {
        this.feature = feature;
        this.isEnabledForUser = isEnabledForUser;
        this.isCanBeModifiedForUser = isCanBeModifiedForUser;
    }
}

/**
 *
 * @param featureFlagIds the flags you want to check
 *
 * @example
 *
 * ```ts
 * const ffStates = await fetchFeatureFlags(['MY_FEATURE1', 'MY_FEATURE2', 'MY_FEATURE3']);
 * ```
 */
async function fetchFeatureFlags<T extends string[] = []>(featureFlagIds: [...T] = [] as unknown as [...T]): Promise<FFState<T>> {
    // const logger = useLogger();

    if (featureFlagIds.length === 0) {
        return {} as FFState<T>;
    }

    const ffList = featureFlagIds.join(',');
    const endpoint = `/api/feature-flipping/fetch/${ffList}`;

    let ffStates: FFState<T> = featureFlagIds.reduce((states, flagId) => {
        states[flagId] = false;
        return states;
    }, {} as FFState<T>);

    try {
        ffStates = await useUniversalFetch<FFState<T>>(endpoint);
    } catch (e) {
        // logger.error(`Error while fetching FF states ["${ffList}"]`);
    }
    return ffStates;
}

async function switchStateFeatureFlag(featureFlagId: string, isEnabled: boolean): Promise<boolean> {
    const logger = useLogger();
    const endpoint = `/api/feature-flipping/${featureFlagId}/state`;

    const enabled = false;
    try {
        await useUniversalPut<void>(endpoint, {
            query: {enabled: isEnabled},
        });
    } catch (e) {
        logger.error(`Error while changing status of FF "${featureFlagId}".`);
    }
    return enabled;
}

/**
 *
 * @example
 *
 * ```ts
 * const featureFlags = await fetchAllFeatureFlags();
 * ```
 */
async function fetchAllFeatureFlags(): Promise<FeatureFlag[]> {
    // const logger = useLogger();

    const endpoint = '/api/feature-flipping';

    try {
        return await useUniversalFetch<FeatureFlag[]>(endpoint);
    } catch (e) {
        // logger.error(`Error while fetching FF states ["${ffList}"]`);
    }
    return [];
}

export function useFeatureFlag() {
    return {
        isFeatureEnabledForHost,
        isFeatureEnabled,
        fetchFeatureFlags,
        fetchAllFeatureFlags,
        switchStateFeatureFlag,
    };
}
