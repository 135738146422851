<template>
    <ErrorPageLayout class="error-page">
        <template #title>
            {{ $t((statusCodeOrOtherErrorType + '.title.page') as any) }}
        </template>

        <template #description>
            <p v-html="$t(statusCodeOrOtherErrorType + '.body.page' as any)" />
        </template>

        <template v-if="isDev" #details>
            <pre class="error-page__stack" v-html="`${error.message} ${error.stack}`" />
        </template>

        <template #actions>
            <VJoyButton variant="main" class="error-page__back-cta" @click="handleError">
                {{ $t('generic.action.go.back') }}
            </VJoyButton>
        </template>
    </ErrorPageLayout>
</template>

<script setup lang="ts">
    import {computed, toRefs} from 'vue';
    import type {NuxtError} from 'nuxt/app';
    import {clearError, useRouter, navigateTo} from '#imports';
    import ErrorPageLayout from './components/ErrorPageLayout.vue';
    import {VJoyButton} from '@maltjoy/core-vue';

    type ErrorDataWithType = {type: string};

    interface Props {
        error: Partial<NuxtError>;
    }
    const props = defineProps<Props>();

    const {error} = toRefs(props);

    if (import.meta.server) {
        // This is a test before trying to send the errors to Sentry
        console.info('Error page displayed', error.value);
    }

    function hasErrorDataType(error: unknown): error is ErrorDataWithType {
        return (error as ErrorDataWithType).type !== undefined;
    }

    const statusCodeOrOtherErrorType = computed(() => {
        if (
            import.meta.client &&
            ((error.value.data && hasErrorDataType(error.value.data) && error.value.data.type === 'cookie-verify') ||
                (!error.value.statusCode && !navigator.cookieEnabled))
        ) {
            return '40x.cookies.disabled';
        }

        if (error.value.statusCode) {
            return error.value.statusCode;
        }

        return '405';
    });

    const isDev = process.dev;

    const router = useRouter();

    function handleError() {
        clearError();

        if (document.referrer && document.referrer.includes(window.location.host)) {
            router.back();
        } else {
            navigateTo('/', {external: true});
        }
    }
</script>

<style scoped lang="scss">
    .error-page {
        min-height: 100vh;
        width: 100%;

        &__stack {
            background-color: var(--joy-color-neutral-50);
            width: 100%;
            color: white;
            overflow-x: auto;
            border-radius: var(--joy-core-radius-3);
            padding: var(--joy-core-spacing-4);
            text-align: left;
        }

        &__back-cta {
            @media screen and (max-width: 767px) {
                width: 100%;
            }
        }
    }
</style>
