import type {ReadonlyState, MutableState} from '@malt/types-utils';
import type {Ref} from 'vue';

/**
 * Apply a readonly type to a value
 * @param stateValue The value does not change, only it's TS type
 *
 * @example
 *
 * ```ts
 * const myStore = defineStore('my-store', () => {
 *      const myValue = ref(false);
 *      return {
 *          myValue: readonlyState(myValue) // it guarantees that 'myValue' can not be mutated
 *      }
 * })
 *
 * ```
 */
export const readonlyState = <T>(stateValue: Ref<T>) => {
    return stateValue as ReadonlyState<T>;
};

/**
 * Explicitly say that the returned value is mutable.
 * It can be used to authorize mutation of a readonly value.
 * It can also be useful to test stores that have readonly values.
 *
 * @param stateValue The value does not change, only it's TS type
 */
export const mutableState = <T>(stateValue: Ref<T>) => {
    return stateValue as MutableState<T>;
};
