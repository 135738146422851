import {addRouteMiddleware, useAsyncData} from '#app';
import {defineNuxtPlugin, useLogger, useUniversalFetch} from '#imports';
import {useMaltVisitorId} from '../composables/useMaltVisitorId';

export default defineNuxtPlugin(async () => {
    const logger = useLogger();
    const maltVisitorId = useMaltVisitorId();

    async function updateMaltVisitorId() {
        if (!maltVisitorId.value) {
            const {data: visitorId, error} = await useAsyncData('visitorId', () =>
                useUniversalFetch<string>('/api/tracking', {
                    headers: {Accept: 'text/plain', 'Content-Type': 'text/plain'},
                }),
            );

            if (error.value) {
                logger.warn('Error while trying to get visitorId. We will ignore this error', error.value);
                return;
            }

            if (visitorId.value) {
                maltVisitorId.value = visitorId.value;
            }
        }
    }

    await updateMaltVisitorId();

    addRouteMiddleware(
        'visitorId',
        async () => {
            await updateMaltVisitorId();
        },
        {global: true},
    );
});
