import {useLogger, useUniversalFetch} from '#imports';
import type {HostSettingsResource} from '../types';
import {FetchError} from 'ofetch';

export default function useHostSettings(): Promise<HostSettingsResource | undefined> {
    const logger = useLogger();

    return useUniversalFetch<HostSettingsResource>('/api/host-settings/current').catch((error: unknown) => {
        if (error instanceof FetchError) {
            logger.error(`Error while fetching host settings. Error: message=${error.message}, status=${error.statusCode}`);
        } else {
            logger.error('Error while fetching host settings', error);
        }
        return undefined;
    });
}
