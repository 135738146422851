import {defineNuxtPlugin, useAuth, useRuntimeConfig, useUniversalFetch} from '#imports';
import {useRoute, useRouter} from 'vue-router';
import {name} from '../../common';
import {storeToRefs} from 'pinia';
import {watch} from 'vue';

interface SentryConfig {
    dsn: string;
    environment: string;
    application: string;
}

export default defineNuxtPlugin({
    parallel: true,
    async setup(nuxtApp) {
        const runtime = useRuntimeConfig();

        const application = runtime.public.application;
        const {captureConsoleLevels, excludedRouteNames} = runtime.public[name];

        const route = !runtime.public.isMF ? useRoute() : undefined;
        const router = !runtime.public.isMF ? useRouter() : undefined;
        const authStore = useAuth();

        // Bind `window.captureException` function to `console.error` before `setUpAlerting` is called
        // eslint-disable-next-line no-console
        window.captureException = console.error;

        try {
            // route can be undefined if no router is used (e.g. in Micro-Frontends)
            const currentRoute = route?.name?.toString();
            const isCurrentRouteExcluded = currentRoute ? excludedRouteNames.includes(currentRoute) : false;

            if (!isCurrentRouteExcluded) {
                const config = await useUniversalFetch<SentryConfig>('/api/sentry/config');
                if (config && config.dsn) {
                    const {setUpAlerting, setUser} = await import('@malt/alerting-vue3/src/configuration');
                    const {user} = storeToRefs(authStore);

                    // Setup without user because it's not ready
                    setUpAlerting({
                        configuration: {
                            dsn: config.dsn,
                            environment: config.environment,
                            application: application || config.application,
                            captureConsoleLevels,
                        },
                        app: nuxtApp.vueApp,
                        router,
                    });

                    // Set the user when it's ready
                    watch(user, (newUser) => {
                        if (newUser) {
                            const selectedIdentity = newUser.identities?.find((identity) => identity.selected);
                            const sentryUser = {
                                accountId: newUser.id,
                                identityId: selectedIdentity?.id ?? '',
                                email: newUser.email,
                            };
                            setUser({
                                connectedUser: sentryUser,
                            });
                        }
                    });
                }
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error('Unable to load sentry configuration', error);
        }
    },
});
