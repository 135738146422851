import {defineNuxtPlugin} from '#imports';
import {useRoute} from 'vue-router';
import {useDataTracking} from '../composables/useDataTracking';

export default defineNuxtPlugin((nuxtApp) => {
    const route = useRoute();
    const {trackPageView} = useDataTracking();

    nuxtApp.hook('page:finish', () => {
        if (route.meta.trackPageView !== false) {
            trackPageView();
        }
    });
});
