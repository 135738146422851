import {useNuxtApp} from 'nuxt/app';
// @ts-ignore
// eslint-disable-next-line
// prettier-ignore
import type {AllTranslationsRecord, TupleIndices, ArrayElement, SingleParameter, ShallowExact, IsEqual, AllowedFunctionArguments, TranslationKey, StrictExtraParameters} from './i18n.types';

type CommonKeyRecord = Record<string, SingleParameter[] | Record<string, any>>;

/**
 * function definition for simple arguments checks, will not report errors when too much arguments are passed
 */
export interface SimpleTypedTFunction<TAllowedKeys extends string, TKeysRecord extends CommonKeyRecord> {
    <TKey extends TAllowedKeys>(
        keyName: TKey,
        ...[params]: TKey extends keyof TKeysRecord
            ? TKeysRecord[TKey] extends [never]
                ? [...any]
                : [
                      TKeysRecord[TKey] extends [...any[]]
                          ? TKeysRecord[TKey] | Record<TupleIndices<TKeysRecord[TKey]>, SingleParameter>
                          : TKeysRecord[TKey],
                  ]
            : [...any]
    ): string;
}
/**
 * Strict function check, will throw error for any incompatible type
 */
export interface TypedTFunction<TAllowedKeys extends string, TKeysRecord extends CommonKeyRecord> {
    <
        TKey extends TAllowedKeys,
        TParams extends TKey extends keyof TKeysRecord
            ? TKeysRecord[TKey] extends [never]
                ? never
                : TKeysRecord[TKey] extends [...any[]]
                ? TKeysRecord[TKey] | Record<TupleIndices<TKeysRecord[TKey]>, SingleParameter>
                : TKeysRecord[TKey]
            : never,
        TParamsInfer extends [...TParams[]] = [],
        TValid = TParamsInfer extends any[]
            ? /* Validate [x, x] array args */
              TParamsInfer extends [[...any[]]]
                ? IsEqual<TParamsInfer, [...TParams[]]> extends true
                    ? true
                    : false
                : TKey extends keyof TKeysRecord
                ? /* Validate Object args */
                  TKeysRecord[TKey] extends [never]
                    ? /* Object is empty '{}' */ false
                    : TKeysRecord[TKey] extends [...any[]]
                    ? TParamsInfer /* Object matches *exactly* the structure of the requested array */ extends [
                          ShallowExact<Record<TupleIndices<TKeysRecord[TKey]>, SingleParameter>, ArrayElement<TParamsInfer>>,
                      ]
                        ? true
                        : true extends false
                        ? TParamsInfer extends never[]
                            ? false
                            : true
                        : false
                    : false
                : false
            : false,
    >(
        keyName: TKey,
        /** @ts-ignore */
        ...[params]: TValid extends true
            ? TParamsInfer extends [never]
                ? []
                : TParamsInfer
            : TKey extends keyof TKeysRecord
            ? TKeysRecord[TKey] extends [never]
                ? []
                : [TKeysRecord[TKey]]
            : []
    ): string;
}

export function useTranslation(): {
    t: StrictExtraParameters extends true
        ? TypedTFunction<TranslationKey, AllTranslationsRecord>
        : SimpleTypedTFunction<TranslationKey, AllTranslationsRecord>;
} {
    // In some contexts (stores or middlewares for instance), we get this error: i18next-vue: No Vue instance in context
    // In that cases, we can fallback to $i18next instance from the Nuxt application

    return useNuxtApp().$i18next as any;
}
