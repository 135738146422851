/* tslint:disable */
/* eslint-disable */
/**
 * Registration API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { mapValues } from '../runtime';
import type { AccountInformationIdentityType } from './';
import {
    AccountInformationIdentityTypeFromJSON,
    AccountInformationIdentityTypeFromJSONTyped,
    AccountInformationIdentityTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface AccountInformationIdentity
 */
export interface AccountInformationIdentity {
    /**
     * 
     * @type {string}
     * @memberof AccountInformationIdentity
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof AccountInformationIdentity
     */
    selected: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccountInformationIdentity
     */
    freelancerCompanyId?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountInformationIdentity
     */
    clientCompanyId?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountInformationIdentity
     */
    clientCompanyName?: string;
    /**
     * 
     * @type {AccountInformationIdentityType}
     * @memberof AccountInformationIdentity
     */
    identityType: AccountInformationIdentityType;
    /**
     * 
     * @type {boolean}
     * @memberof AccountInformationIdentity
     */
    admin: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccountInformationIdentity
     */
    features: Array<string>;
}

/**
 * Check if a given object implements the AccountInformationIdentity interface.
 */
export function instanceOfAccountInformationIdentity(value: object): value is AccountInformationIdentity {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('selected' in value) || value['selected'] === undefined) return false;
    if (!('identityType' in value) || value['identityType'] === undefined) return false;
    if (!('admin' in value) || value['admin'] === undefined) return false;
    if (!('features' in value) || value['features'] === undefined) return false;
    return true;
}

export function AccountInformationIdentityFromJSON(json: any): AccountInformationIdentity {
    return AccountInformationIdentityFromJSONTyped(json, false);
}

export function AccountInformationIdentityFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountInformationIdentity {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'selected': json['selected'],
        'freelancerCompanyId': json['freelancerCompanyId'] == null ? undefined : json['freelancerCompanyId'],
        'clientCompanyId': json['clientCompanyId'] == null ? undefined : json['clientCompanyId'],
        'clientCompanyName': json['clientCompanyName'] == null ? undefined : json['clientCompanyName'],
        'identityType': AccountInformationIdentityTypeFromJSON(json['identityType']),
        'admin': json['admin'],
        'features': json['features'],
    };
}

export function AccountInformationIdentityToJSON(value?: AccountInformationIdentity | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'selected': value['selected'],
        'freelancerCompanyId': value['freelancerCompanyId'],
        'clientCompanyId': value['clientCompanyId'],
        'clientCompanyName': value['clientCompanyName'],
        'identityType': AccountInformationIdentityTypeToJSON(value['identityType']),
        'admin': value['admin'],
        'features': value['features'],
    };
}

