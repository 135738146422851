import {watch} from 'vue';
import {useCookie, useState} from '#app';

export function useApiSetCookies() {
    const sessionCookie = useCookie('SESSION');
    const csrfCookie = useCookie('XSRF-TOKEN');
    const visitorIdCookie = useCookie('malt-visitorId');
    const rememberMeCookie = useCookie('remember-me');

    const apiSessionId = useState('apiSessionId', () => sessionCookie.value);
    const apiCsrfToken = useState('apiCsrfToken', () => csrfCookie.value);
    const visitorId = useState('visitorId', () => visitorIdCookie.value);
    const rememberMe = useState('rememberMe', () => rememberMeCookie.value);

    watch(apiSessionId, (value) => {
        // Update cookie from state
        sessionCookie.value = value;
    });

    watch(apiCsrfToken, (value) => {
        // Update cookie from state
        csrfCookie.value = value;
    });

    watch(visitorId, (value) => {
        // Update cookie from state
        visitorIdCookie.value = value;
    });

    watch(rememberMe, (value) => {
        // Update cookie from state
        rememberMeCookie.value = value;
    });

    return {
        apiSessionId,
        apiCsrfToken,
        visitorId,
        rememberMe,
    };
}
