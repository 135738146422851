import type {ConsolaReporter} from 'consola';
import {createConsola} from 'consola';
import type {Logger} from './types';
import {JSONReporter} from './JSONReporter';
import type {H3Event} from 'h3';

export function createLogger(event?: H3Event): Logger {
    if (import.meta.server) {
        const options: {fancy?: boolean; reporters?: ConsolaReporter[]} = {fancy: process.dev || !!process.env?.MALT_FANCY_LOGGING};
        if (!options.fancy) {
            options.reporters = [new JSONReporter(event)];
        }
        const logger = createConsola(options);
        logger.wrapConsole();

        return {
            error: logger.error,
            warn: logger.warn,
            info: logger.info,
            debug: logger.debug,
            trace: logger.trace,
        };
    }

    return {
        error(message?: any, ...optionalParams: any[]) {
            console.error(message, ...optionalParams);
        },
        warn(message?: any, ...optionalParams: any[]) {
            console.warn(message, ...optionalParams);
        },
        info(message?: any, ...optionalParams: any[]) {
            console.info(message, ...optionalParams);
        },
        debug(message?: any, ...optionalParams: any[]) {
            console.debug(message, ...optionalParams);
        },
        trace(message?: any, ...optionalParams: any[]) {
            console.trace(message, ...optionalParams);
        },
    };
}
