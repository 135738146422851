import {defineNuxtPlugin, useAsyncData, useLogger, useUniversalFetch} from '#imports';
import {useDataLayer} from '../composables/useDataLayer';

export default defineNuxtPlugin(async () => {
    const logger = useLogger();
    const dataLayer = useDataLayer();

    const {data, error} = await useAsyncData('dataLayer', () => useUniversalFetch<Record<string, any>>('/api/datalayer'));
    if (error.value) {
        logger.error('Error while fetching data layer', error.value);
        return;
    }

    if (!data.value) {
        logger.error('No data layer found');
        return;
    }

    dataLayer.value = data.value;
});
