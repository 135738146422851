import {useCookie, useState} from '#imports';

export const useStoredKeys = () => useState<Set<string>>('stored-keys', () => new Set<string>());

export function useTranslationAdminTools() {
    // We use cookies to keep it synchronized between SSR and CSR
    const cookieDebug = useCookie('nuxt-i18n-module-debug');
    const cookieLogs = useCookie('nuxt-i18n-module-logs');

    function switchDebugTool(isActive: boolean): void {
        cookieDebug.value = isActive ? '1' : '0';
    }
    function switchLogsTool(isActive: boolean): void {
        cookieLogs.value = isActive ? '1' : '0';
    }

    function isActivatedDebugTool(): boolean {
        return !!cookieDebug.value;
    }
    function isActivatedLogsTool(): boolean {
        return !!cookieLogs.value;
    }

    return {
        isActivatedDebugTool,
        isActivatedLogsTool,
        switchDebugTool,
        switchLogsTool,
    };
}
