import {useRoute, useRuntimeConfig} from '#app';
import {guestHost, useLocale, useServerSeoMeta, useHead} from '#imports';

export const use3rdParties = () => {
    useServerSeoMeta({
        fbAppId: '2305272732',
    });
};

export interface TwitterMetaTagsParams {
    description?: string;
    card?: string;
    image?: string;
    title?: string;
    t: any;
}

export class TwitterMetaTags {
    card: string;
    image: string;
    title: string;
    description: string;
    constructor(params: TwitterMetaTagsParams) {
        this.card = params.card || 'summary';
        this.image = params.image || 'https://dam.malt.com/rebranding2020/malt-logo/malt-banner-logo';
        this.title = params.title || 'Malt';
        this.description = params.description || params.t('seo.malt.meta.description');
    }
}

export const useTwitterMetaTags = (twitterMetaTags: TwitterMetaTags) => {
    useHead({
        meta: [
            {
                name: 'twitter:card',
                content: twitterMetaTags.card,
            },
            {
                name: 'twitter:image',
                content: twitterMetaTags.image,
            },
            {
                name: 'twitter:title',
                content: twitterMetaTags.title,
            },
            {
                name: 'twitter:description',
                content: twitterMetaTags.description,
            },
        ],
    });
};

export class OpenGraphMetaTagDetails {
    name: string;
    content: string;

    constructor(name: string, content: string) {
        this.name = name;
        this.content = content;
    }
}

export interface OpenGraphMetaTagParams {
    type?: string;
    image?: string;
    title?: string;
    description?: string;
    url?: string;
    details?: OpenGraphMetaTagDetails[];
    t: any;
}

export class OpenGraphMetaTag {
    type: string;
    image: string;
    title: string;
    description: string;
    url?: string;
    details?: OpenGraphMetaTagDetails[];

    constructor(params: OpenGraphMetaTagParams) {
        this.type = params.type || 'website';
        this.image = params.image || 'https://dam.malt.com/rebranding2020/malt-logo/malt-banner-logo';
        this.title = params.title || 'Malt';
        this.description = params.description || params.t('seo.malt.meta.description');
        this.url = params.url;
        this.details = params.details;
    }
}
export const useOpenGraphMetaTags = (openGraphMetaTags: OpenGraphMetaTag) => {
    useHead({
        meta: [
            {
                property: 'og:type',
                content: openGraphMetaTags.type,
            },
            {
                property: 'og:image',
                content: openGraphMetaTags.image,
            },
            {
                property: 'og:title',
                content: openGraphMetaTags.title,
            },
            {
                property: 'og:description',
                content: openGraphMetaTags.description,
            },
            ...(openGraphMetaTags.details || []).map((detail) => ({
                property: detail.name,
                content: detail.content,
            })),
        ],
    });
    if (openGraphMetaTags.url) {
        useHead({
            meta: [
                {
                    property: 'og:url',
                    content: openGraphMetaTags.url,
                },
            ],
        });
    }
};

export const useHTMLAttrsLang = () => {
    const {locale} = useLocale();
    useHead({
        htmlAttrs: {
            lang: locale.value,
        },
    });
};

export const useCanonicalURL = () => {
    const host = guestHost();
    const config = useRuntimeConfig();
    const route = useRoute();
    const href = host + config.app.baseURL + route.path;
    useHead({
        link: [
            {
                rel: 'canonical',
                href,
            },
        ],
    });
};
