/* tslint:disable */
/* eslint-disable */
/**
 * Registration API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { mapValues } from '../runtime';
/**
 * Details of a restriction requiring the user to sign in using SSO.
 * @export
 * @interface SsoSigninMethodRestriction
 */
export interface SsoSigninMethodRestriction {
    /**
     * The URL where to redirect the user to sign in using SSO.
     * @type {string}
     * @memberof SsoSigninMethodRestriction
     */
    authenticationUrl: string;
    /**
     * The name of the organization linked to the domain of the provided email address and which mandates using
     * SSO to sign in.
     * 
     * @type {string}
     * @memberof SsoSigninMethodRestriction
     */
    organizationName?: string;
}

/**
 * Check if a given object implements the SsoSigninMethodRestriction interface.
 */
export function instanceOfSsoSigninMethodRestriction(value: object): value is SsoSigninMethodRestriction {
    if (!('authenticationUrl' in value) || value['authenticationUrl'] === undefined) return false;
    return true;
}

export function SsoSigninMethodRestrictionFromJSON(json: any): SsoSigninMethodRestriction {
    return SsoSigninMethodRestrictionFromJSONTyped(json, false);
}

export function SsoSigninMethodRestrictionFromJSONTyped(json: any, ignoreDiscriminator: boolean): SsoSigninMethodRestriction {
    if (json == null) {
        return json;
    }
    return {
        
        'authenticationUrl': json['authenticationUrl'],
        'organizationName': json['organizationName'] == null ? undefined : json['organizationName'],
    };
}

export function SsoSigninMethodRestrictionToJSON(value?: SsoSigninMethodRestriction | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'authenticationUrl': value['authenticationUrl'],
        'organizationName': value['organizationName'],
    };
}

