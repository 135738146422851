/* tslint:disable */
/* eslint-disable */
/**
 * Registration API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const AccountInformationIdentityType = {
    Client: 'CLIENT',
    Freelance: 'FREELANCE',
    Undefined: 'UNDEFINED'
} as const;
export type AccountInformationIdentityType = typeof AccountInformationIdentityType[keyof typeof AccountInformationIdentityType];


export function instanceOfAccountInformationIdentityType(value: any): boolean {
    for (const key in AccountInformationIdentityType) {
        if (Object.prototype.hasOwnProperty.call(AccountInformationIdentityType, key)) {
            // @ts-ignore
            if (AccountInformationIdentityType[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function AccountInformationIdentityTypeFromJSON(json: any): AccountInformationIdentityType {
    return AccountInformationIdentityTypeFromJSONTyped(json, false);
}

export function AccountInformationIdentityTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountInformationIdentityType {
    return json as AccountInformationIdentityType;
}

export function AccountInformationIdentityTypeToJSON(value?: AccountInformationIdentityType | null): any {
    return value as any;
}

