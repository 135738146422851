import {getCurrentInstance} from 'vue';
import {useNuxtApp} from '#imports';

export function useIsMountedAppSession() {
    const nuxtApp = useNuxtApp();

    function isMountedAppSession() {
        const instance = getCurrentInstance();
        return !nuxtApp.isHydrating && (!instance || instance?.isMounted) && import.meta.client;
    }

    return isMountedAppSession;
}
