import {defineNuxtPlugin, useAuth} from '#imports';

export default defineNuxtPlugin((nuxtApp) => {
    const auth = useAuth();

    nuxtApp.hook('app:mounted', () => {
        // Ensure the identity used by the page is propagated with any calls made to the server.
        // Indeed, we may have several tabs opened and using a different identity at the same time.
        // The risk is that the identity used by another tab would be seen by the server for API
        // calls made by the current tab.
        window.addEventListener('focus', auth.updateSelectedIdentityCookie);
    });
});
