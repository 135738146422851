import {useRequestHeaders, useRuntimeConfig} from '#imports';
import cloneDeep from 'lodash-es/cloneDeep';
import {useCookie} from 'nuxt/app';
import {type $Fetch, $fetch, FetchError, type FetchResponse} from 'ofetch';
import {withBase} from 'ufo';
import type {Ref} from 'vue';
import {useApiSetCookies} from './useApiSetCookies';
import {useBackendUrl} from './useBackendUrl';
import {useSpanWrap} from './useSpanWrap';

export {FetchError as OFetchError} from 'ofetch';

function makeUniversalUrl(url: string, baseURL?: string): string {
    const isAbsolute = /^(?:[a-z+]+:)?\/\//i.test(url);
    if (!isAbsolute && import.meta.server) {
        const host = guestHost();
        // If url aleady starts with baseURL, it will not be added again.
        const urlWithBase = withBase(url, baseURL ?? '');
        return new URL(urlWithBase, host).toString();
    }
    return url;
}

function prepareOptions(opts: NonNullable<Parameters<$Fetch>[1]>) {
    const options = cloneDeep(opts);
    const runtimeConfig = useRuntimeConfig();

    let headersToForwardFromClient: Record<string, string | undefined> = {};

    if (import.meta.server) {
        headersToForwardFromClient = useRequestHeaders();
        headersToForwardFromClient[`x-malt-ssr`] = 'true';

        const {apiSessionId, apiCsrfToken, visitorId, rememberMe} = useApiSetCookies();

        if (apiSessionId.value) {
            headersToForwardFromClient.cookie = appendToCookieString(headersToForwardFromClient.cookie, 'SESSION', apiSessionId.value);
        }
        if (apiCsrfToken.value) {
            headersToForwardFromClient.cookie = appendToCookieString(headersToForwardFromClient.cookie, 'XSRF-TOKEN', apiCsrfToken.value);
            if (!['GET', 'HEAD', 'TRACE', 'OPTIONS'].includes(opts.method || '')) {
                headersToForwardFromClient['x-xsrf-token'] = apiCsrfToken.value;
            }
        }
        if (visitorId.value) {
            headersToForwardFromClient.cookie = appendToCookieString(headersToForwardFromClient.cookie, 'malt-visitorId', visitorId.value);
        }
        if (rememberMe.value) {
            headersToForwardFromClient.cookie = appendToCookieString(headersToForwardFromClient.cookie, 'remember-me', rememberMe.value);
        }

        // SEO bot crawler related behavior fix
        if (headersToForwardFromClient.accept?.includes('text/html')) {
            headersToForwardFromClient.accept = '*/*';
        }
        // Remove Accept-Encoding header as the server fetch client may
        // not support all what supported by the browser like zstd
        delete headersToForwardFromClient['accept-encoding'];
    }

    const csrf = useCookie('XSRF-TOKEN');
    const {appVersion} = runtimeConfig.public;

    options.headers = {
        ...{Accept: 'application/json'},
        ...(options?.headers && options.headers),
        ...(csrf.value && {'x-xsrf-token': csrf.value}),
        ...(appVersion && {'x-app-version': appVersion}),
        ...headersToForwardFromClient,
    };

    // Default timeout on SSR is 5 minutes. We set it to 1 minute by default
    if (import.meta.server) {
        const fetchTimeout = opts.timeout || runtimeConfig.ssrFetchTimeout;
        if (!!fetchTimeout && !isNaN(fetchTimeout as any)) {
            // sorry for "as any" but isNaN should cast string to number if necessary

            if (opts.timeout) {
                options.timeout = +fetchTimeout;
            } else {
                options.timeout = +fetchTimeout * 1000; // From our env var, we use seconds to indicate the timeout
            }
        }
    }

    return options;
}

function appendToCookieString(cookieString: string | undefined, cookieKey: string, cookieValue: string) {
    const cookieParts = (cookieString || '').split(';').filter((part) => !part.trim().startsWith(`${cookieKey}=`));
    const newCookie = `${cookieKey}=${cookieValue}`;
    return [...cookieParts, newCookie].join('; ');
}

function getCookieValueFromResponse<T>(cookieName: string, response?: FetchResponse<T>) {
    const setCookieValue = response?.headers?.get('Set-Cookie');
    if (!setCookieValue) {
        return;
    }

    const cookieValue = setCookieValue.split(`${cookieName}=`)[1];
    if (!cookieValue) {
        return;
    }

    return cookieValue.split(';')[0];
}

function updateServerApiCookies<T>(
    {
        apiSessionId,
        apiCsrfToken,
        visitorId,
        rememberMe,
    }: {
        apiSessionId: Ref<string | undefined | null>;
        apiCsrfToken: Ref<string | undefined | null>;
        visitorId: Ref<string | undefined | null>;
        rememberMe: Ref<string | undefined | null>;
    },
    response?: FetchResponse<T>,
) {
    const newSessionId = getCookieValueFromResponse('SESSION', response);
    if (newSessionId) {
        apiSessionId.value = newSessionId;
    }

    const newCsrfToken = getCookieValueFromResponse('XSRF-TOKEN', response);
    if (newCsrfToken) {
        apiCsrfToken.value = newCsrfToken;
    }

    const newVisitorId = getCookieValueFromResponse('malt-visitorId', response);
    if (newVisitorId) {
        visitorId.value = newVisitorId;
    }

    const newRememberMe = getCookieValueFromResponse('remember-me', response);
    if (newRememberMe) {
        rememberMe.value = newRememberMe;
    }
}

export function guestHost() {
    if (import.meta.server) {
        const headers = useRequestHeaders();
        if (!headers.host) {
            return 'https://www.malt.fr';
        }
        return `https://${headers.host}`;
    } else {
        return window.location.origin;
    }
}

/**
 *
 * Create an ofetch instance with a custom baseURL
 *
 * It returns a useUniversalFetch function based on the instance
 *
 * @public-api
 *
 * @param opts - Options from ofetch
 */
export function createUniversalFetch(options: {baseURL: string}): typeof useUniversalFetch {
    const universalFetchRaw = createUniversalFetchRaw(options);

    async function universalFetch<T>(url: string, opts: Parameters<$Fetch>[1] = {method: 'GET'}): Promise<T> {
        const response = await universalFetchRaw(url, opts);
        return response._data as Promise<T>;
    }

    return universalFetch;
}

/**
 *
 * Create an ofetch instance with a custom baseURL
 *
 * It returns a useUniversalFetch function based on the instance
 *
 * @public-api
 *
 * @param opts - Options
 */
export function createUniversalFetchRaw(options: {baseURL: string}): typeof useUniversalFetchRaw {
    const $fetchInstance = $fetch.create(options);
    return createUniversalFetchRawWith$FetchInstance($fetchInstance.raw);
}

function createUniversalFetchRawWith$FetchInstance(fetchClientRaw: typeof $fetch.raw): typeof useUniversalFetchRaw {
    async function universalFetchRaw<T>(url: string, opts: Parameters<$Fetch>[1] = {method: 'GET'}): Promise<FetchResponse<T>> {
        const {apiSessionId, apiCsrfToken, visitorId, rememberMe} = useApiSetCookies();

        const universalURL = makeUniversalUrl(url, opts.baseURL);
        const fetchOptions = prepareOptions(opts);

        try {
            if (import.meta.server && process.env.FETCH_DEBUG) {
                const prefix = process.dev ? '\x1B[33m%s\x1B[0m' : '';
                console.log(`${prefix}${universalURL}\n${JSON.stringify(fetchOptions, null, 2)}`, 'useSpanWrap');
            }

            const response = await useSpanWrap(() => fetchClientRaw(universalURL, fetchOptions));
            updateServerApiCookies({apiSessionId, apiCsrfToken, visitorId, rememberMe}, response);
            return response;
        } catch (error: unknown) {
            updateServerApiCookies({apiSessionId, apiCsrfToken, visitorId, rememberMe}, (error as FetchError).response);
            if (error instanceof FetchError) {
                if (import.meta.client && typeof window !== 'undefined' && (error.statusCode === 403 || error.statusCode === 401)) {
                    document.dispatchEvent(new Event('user-may-be-disconnected'));
                }
            }
            return Promise.reject(error);
        }
    }

    return universalFetchRaw;
}

/**
 * ## Method : *useUniversalFetchRaw<T>(url: string, opts: Parameters<$Fetch>[1] = {method: 'GET'})*
 *
 * Make universal API calls, returning raw response (headers included).
 *
 * @public-api
 *
 * @param url       A relative or absolute URL passed as string
 * @param opts      Fetch options from ofetch
 */
export async function useUniversalFetchRaw<T>(url: string, opts: Parameters<$Fetch>[1] = {method: 'GET'}): Promise<FetchResponse<T>> {
    const baseURL = useBackendUrl();
    // Using $fetch global instance
    const actualImplementation = createUniversalFetchRawWith$FetchInstance($fetch.raw);
    return await actualImplementation(url, {...opts, baseURL: opts.baseURL ?? baseURL});
}

/**
 * ## Method : *useUniversalFetch<T>(url: string, opts: Parameters<$Fetch>[1] = {method: 'GET'})*
 *
 * Make universal API calls.
 *
 * If the call is made client-side, the composable makes a call to the endpoint defined by the URL, be it relative or
 * absolute.
 *
 * If the call is made server-side, the composable resolve the URL to an absolute one if it's not already the case.
 * Requests headers are used to infer host and protocol in this case.
 *
 * @public-api
 *
 * @param url       A relative or absolute URL passed as string
 * @param opts      Fetch options from ofetch
 */
export async function useUniversalFetch<T>(url: string, opts: Parameters<$Fetch>[1] = {method: 'GET'}): Promise<T> {
    const response = await useUniversalFetchRaw<T>(url, opts);
    return response._data as Promise<T>;
}

export function useUniversalPost<T>(url: string, opts: Parameters<$Fetch>[1] = {}) {
    return useUniversalFetch<T>(url, {...opts, method: 'POST'});
}

export function useUniversalPut<T>(url: string, opts: Parameters<$Fetch>[1] = {}) {
    return useUniversalFetch<T>(url, {...opts, method: 'PUT'});
}

export function useUniversalDelete<T>(url: string, opts: Parameters<$Fetch>[1] = {}) {
    return useUniversalFetch<T>(url, {...opts, method: 'DELETE'});
}
