import {useRuntimeConfig} from '#imports';

export function useBackendUrl() {
    const config = useRuntimeConfig();
    let baseUrl = config.app.baseURL;
    if (config.public.backendUrl) {
        baseUrl = config.public.backendUrl;
    }
    return baseUrl.replace(/\/+$/, '');
}
