import type {Span, Tracer} from 'dd-trace';
import {useRequestEvent} from '#app';

interface SpanOptions {
    httpMethod: string;
    kind: string;
    name: string;
}

export function useSpanWrap<T extends object>(fetchFn: () => Promise<T>, options?: SpanOptions): Promise<T> {
    const event = useRequestEvent();
    const tracer = event?.context?.tracer as Tracer | undefined;
    const childOf = event?.context?.activeSpan as Span | undefined;
    if (tracer && childOf) {
        if (options) {
            return tracer.trace(options.kind, {resource: options.name, type: options.kind, childOf}, fetchFn);
        } else {
            return tracer.scope().activate(childOf, fetchFn);
        }
    }
    return fetchFn();
}
