import {useState} from '#app';
import {useCookie, useLogger, useUniversalFetch} from '#imports';
import type {FetchError} from 'ofetch';
import {computed} from 'vue';
import {fallbackLng} from '../../config';

export function useLocale() {
    const logger = useLogger();
    const i18nCookie = useCookie<string>('i18n', {path: '/'});
    const url = '/api/locale';

    const localeState = useState<string>('i18n', undefined);

    const locale = computed(() => localeState.value || fallbackLng);
    const splittedLocale = computed(() => locale.value.split('-'));
    const languageCode = computed(() => splittedLocale.value[0]);
    const countryCode = computed(() => splittedLocale.value[1]);

    async function _fetchLocale() {
        if (i18nCookie.value) {
            return i18nCookie.value;
        }

        try {
            return await useUniversalFetch<string>(url, {headers: {Accept: 'text/plain', 'Content-Type': 'text/plain'}});
        } catch (err: unknown) {
            const error = err as FetchError;
            logger.error(
                `Error while trying to get locale from ${url}. Will use fallback language instead (${fallbackLng}). Error: message=${error.message}, status=${error.statusCode}`,
            );
            return fallbackLng;
        }
    }

    async function refreshLocale() {
        if (!localeState.value) {
            const newLocale = await _fetchLocale();
            updateLocale(newLocale);
        }
        return localeState.value;
    }

    function updateLocale(newLocale: string) {
        localeState.value = newLocale;
        i18nCookie.value = newLocale;
    }

    return {
        countryCode,
        languageCode,
        locale,
        refreshLocale,
        updateLocale,
    };
}
