import {joinURL} from 'ufo';
import type {ProviderGetImage} from '@nuxt/image';
import {createOperationsGenerator} from '#image';

// Custom provider for @nuxt/image. To be removed if/when https://github.com/nuxt/image/issues/1380 is resolved.
const operationsGenerator = createOperationsGenerator({
    keyMap: {
        fit: 'func',
        format: 'force_format',
        quality: 'q',
        width: 'w',
        height: 'h',
    },
    valueMap: {
        fit: {
            cover: 'crop',
            contain: 'fit',
            fill: 'cover',
            inside: 'bound',
            outside: 'boundmin',
        },
    },
    joinWith: '&',
    formatter: (key: string, value: string) => `${key}=${value}`,
});

export const getImage: ProviderGetImage = (src, {modifiers = {}, baseURL} = {}) => {
    if (!baseURL) {
        // eslint-disable-next-line no-console
        console.warn(`[filerobot] <baseURL> is required to build image URL`);
    }

    const operations = operationsGenerator(modifiers);
    const query = operations ? '?' + operations : '';

    return {
        url: joinURL(baseURL, src) + query,
    };
};
