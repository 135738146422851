import {defineNuxtPlugin} from '#app';
import {createLogger} from '../loggerFactory';

export default defineNuxtPlugin({
    setup(nuxtApp) {
        const logger = createLogger(nuxtApp.ssrContext?.event);
        return {
            provide: {
                logger,
            },
        };
    },
});
