import {useNuxtApp, useUniversalFetch, useUniversalPost} from '#imports';

import type {Dictionary, WebAttributionProperties, SessionReplay} from '../types';

const trackingEndpoint = '/api/t/event';
const sessionEndpoint = '/api/t/session';

type TrackingSession = {timestamp: number; timeout: number; amplitudeApiKey: string};

function extractWebAttributionProperties(): WebAttributionProperties {
    const paramsToExtract: Array<keyof WebAttributionProperties> = [
        'utm_source',
        'utm_medium',
        'utm_campaign',
        'utm_term',
        'utm_content',
        'fbclid',
        'gclid',
    ];

    const searchParams = new URLSearchParams(window.location.search);
    const properties: WebAttributionProperties = {};
    for (const param of paramsToExtract) {
        properties[param] = searchParams.get(param) || null;
    }
    return properties;
}

export function useDataTracking() {
    const trackingSessionReplay = useNuxtApp().$trackingSessionReplay as SessionReplay | undefined;

    async function trackEvent(eventName: string, eventProperties: Dictionary = {}, userProperties: Dictionary = {}): Promise<void> {
        // Tracking should only happen client side
        // We want to avoid 403 errors when the POST call is made too early
        // in the page lifecycle and the CSRF token is missing
        if (import.meta.server) {
            return Promise.resolve();
        }

        try {
            return await useUniversalPost<void>(trackingEndpoint, {
                baseURL: '/',
                body: {
                    event_name: eventName,
                    event_properties: eventProperties,
                    user_properties: userProperties,
                    session_replay_properties: trackingSessionReplay ? trackingSessionReplay.getSessionReplayProperties() : {},
                },
            });
        } catch (_: unknown) {
            // Do nothing with error.
            // Alerting / monitoring is done on the api/t/event endpoint (server side)
            return Promise.resolve();
        }
    }

    function trackPageView() {
        if (import.meta.server) {
            return;
        }

        const url = window.location.href.split(window.location.origin)[1];
        const eventProperties = {url};
        const userProperties = {
            ...extractWebAttributionProperties(),
            referrer: document.referrer || null,
            screen_resolution: `${window.screen.width}x${window.screen.height}`,
        };
        return trackEvent('page_viewed', eventProperties, userProperties);
    }

    function track(eventName: string, eventProperties?: Dictionary, userProperties?: Dictionary) {
        return trackEvent(eventName, eventProperties, userProperties);
    }

    async function getSessionTimestamp(): Promise<TrackingSession> {
        return await useUniversalFetch<TrackingSession>(sessionEndpoint, {
            baseURL: '/',
        });
    }

    return {
        extractWebAttributionProperties,
        trackPageView,
        track,
        getSessionTimestamp,
    };
}
