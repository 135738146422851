<template>
    <NuxtImg
        provider="filerobot"
        :src="computedSrc"
        :alt="alt"
        :width="width"
        :height="height"
        :sizes="sizes"
        :quality="computedQuality"
        densities="x1 x2"
    />
</template>

<script setup lang="ts">
    import {computed, getCurrentInstance} from 'vue';
    import {useLogger} from '#imports';

    interface Props {
        src: string;
        alt: string;
        width?: number | string;
        height?: number | string;
        quality?: number | string;
        sizes?: string;
    }

    const props = defineProps<Props>();

    const logger = useLogger();

    if (process.env.NODE_ENV === 'development' && !props.sizes && !props.width && !props.height) {
        const source = getCurrentInstance()?.parent?.type.__file;
        console.error(`[MaltImg] <width> or <height> or <sizes> is required. Please check usage in ${source}`);
    }

    const regex = /\/\/dam\.malt\.com(.+)$/;

    const computedSrc = computed(() => {
        if (!props.src) {
            logger.warn('[MaltImg] <src> should not be falsy');
            return '';
        }
        const match = props.src.match(regex);
        if (match && match[1]) {
            return match[1];
        }
        return props.src;
    });

    const computedQuality = computed(() => {
        if (props.quality) {
            return props.quality;
        } else if (!props.width && !props.height) {
            return 80;
        }
        return undefined;
    });
</script>
