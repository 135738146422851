
/* tslint:disable */
/* eslint-disable */
/**
 * Registration API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {FetchOptions} from 'ofetch';
import type {
  CurrentSessionDetails,
  SigninMethodRestrictions,
  SigninRequestWithAuthenticationDetails,
} from '../models';
import {
    CurrentSessionDetailsFromJSON,
    CurrentSessionDetailsToJSON,
    SigninMethodRestrictionsFromJSON,
    SigninMethodRestrictionsToJSON,
    SigninRequestWithAuthenticationDetailsFromJSON,
    SigninRequestWithAuthenticationDetailsToJSON,
} from '../models';

export interface CheckExistingFreelancerSignupWorkflowRequest {
    email: string;
    forceNewSignup?: boolean;
}

export interface GetSigninMethodRestrictionsRequest {
    email: string;
    redirect?: string;
}

export interface SigninWithAuthenticationDetailsRequest {
    signinRequestWithAuthenticationDetails: SigninRequestWithAuthenticationDetails;
}

/**
 * 
 */
export class SigninApi extends runtime.BaseAPI {

    /**
     * Check if there is an ongoing freelancer signup workflow for this email
     */
    async checkExistingFreelancerSignupWorkflowRaw(requestParameters: CheckExistingFreelancerSignupWorkflowRequest, initOverrides?: FetchOptions | runtime.InitOverrideFunction): Promise<runtime.FetchResponse<void>> {
        if (requestParameters['email'] == null) {
            throw new runtime.RequiredError(
                'email',
                'Required parameter "email" was null or undefined when calling checkExistingFreelancerSignupWorkflow().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['email'] != null) {
            queryParameters['email'] = requestParameters['email'];
        }

        if (requestParameters['forceNewSignup'] != null) {
            queryParameters['forceNewSignup'] = requestParameters['forceNewSignup'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request<void>({
            path: `/registration/api/check-existing-freelancer-signup-workflow`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return response;
    }

    /**
     * Check if there is an ongoing freelancer signup workflow for this email
     */
    async checkExistingFreelancerSignupWorkflow(requestParameters: CheckExistingFreelancerSignupWorkflowRequest, initOverrides?: FetchOptions | runtime.InitOverrideFunction): Promise<void> {
        await this.checkExistingFreelancerSignupWorkflowRaw(requestParameters, initOverrides);
    }

    /**
     * Get restrictions that apply to user when to sign in on Malt using the provided email address.
     */
    async getSigninMethodRestrictionsRaw(requestParameters: GetSigninMethodRestrictionsRequest, initOverrides?: FetchOptions | runtime.InitOverrideFunction): Promise<runtime.FetchResponse<SigninMethodRestrictions>> {
        if (requestParameters['email'] == null) {
            throw new runtime.RequiredError(
                'email',
                'Required parameter "email" was null or undefined when calling getSigninMethodRestrictions().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['email'] != null) {
            queryParameters['email'] = requestParameters['email'];
        }

        if (requestParameters['redirect'] != null) {
            queryParameters['redirect'] = requestParameters['redirect'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request<SigninMethodRestrictions>({
            path: `/registration/api/signin-method-restrictions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (response._data) { response._data=SigninMethodRestrictionsFromJSON(response._data); }
        return response;
    }

    /**
     * Get restrictions that apply to user when to sign in on Malt using the provided email address.
     */
    async getSigninMethodRestrictions(requestParameters: GetSigninMethodRestrictionsRequest, initOverrides?: FetchOptions | runtime.InitOverrideFunction): Promise<SigninMethodRestrictions> {
        const response = await this.getSigninMethodRestrictionsRaw(requestParameters, initOverrides);
        return response._data;
    }

    /**
     * Sign in the current user with an email and password
     */
    async signinWithAuthenticationDetailsRaw(requestParameters: SigninWithAuthenticationDetailsRequest, initOverrides?: FetchOptions | runtime.InitOverrideFunction): Promise<runtime.FetchResponse<CurrentSessionDetails>> {
        if (requestParameters['signinRequestWithAuthenticationDetails'] == null) {
            throw new runtime.RequiredError(
                'signinRequestWithAuthenticationDetails',
                'Required parameter "signinRequestWithAuthenticationDetails" was null or undefined when calling signinWithAuthenticationDetails().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request<CurrentSessionDetails>({
            path: `/registration/api/signin-request-with-authentication-details`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SigninRequestWithAuthenticationDetailsToJSON(requestParameters['signinRequestWithAuthenticationDetails']),
        }, initOverrides);

        if (response._data) { response._data=CurrentSessionDetailsFromJSON(response._data); }
        return response;
    }

    /**
     * Sign in the current user with an email and password
     */
    async signinWithAuthenticationDetails(requestParameters: SigninWithAuthenticationDetailsRequest, initOverrides?: FetchOptions | runtime.InitOverrideFunction): Promise<CurrentSessionDetails> {
        const response = await this.signinWithAuthenticationDetailsRaw(requestParameters, initOverrides);
        return response._data;
    }

}
